import { FC, ReactNode, useCallback } from "react";

import DecisionButton from "./DecisionButton";
import {
  invalidAccountNumberMsg,
  validateDecisionButton,
} from "../BulkActions/validations";
import { Account } from "../../utils/Interfaces";
import AuthGuard from "../shared/RolesGuard";
import NCPTSSyncButton from "./NCPTSSyncButton";

const deliveredTooltip =
  "NOTE: If the status is “Delivered”, you must click Start Review before rendering a decision on this form";
const returnTooltip = `
  WARNING: Clicking this button will revert the form
  from its current state back to "Delivered" and
  the taxpayer will be notified via email of the status change.
`;

interface DecisionButtonsContainerProps {
  refreshAccount: () => void;
  account: Account;
  handleUpdateFormDecision: Function;
  openModal: Function;
}

const DecisionButtonsContainer: FC<DecisionButtonsContainerProps> = ({
  refreshAccount,
  account,
  handleUpdateFormDecision,
  openModal,
}) => {
  const checkIfStatusIs = useCallback(
    (status: string) => account.status === status,
    [account.status],
  );

  const setStatusTo = useCallback(
    (status: string) => {
      return () => handleUpdateFormDecision(status);
    },
    [handleUpdateFormDecision],
  );

  const validateFor = useCallback(
    (action: string) => {
      return validateDecisionButton({
        action,
        formType: account.formType,
        status: account.status,
        accountNum: account.accountNumberPrimary,
        assignedToName: account.assignedToName,
        syncStatus: account.syncStatus,
      });
    },
    [
      account.status,
      account.formType,
      account.accountNumberPrimary,
      account.assignedToName,
      account.syncStatus,
    ],
  );

  const getooltip = useCallback(
    (status: string, isDisabled?: boolean, disabledContent?: ReactNode) => {
      if (!isDisabled) return `Click to ${status} form`;
      return disabledContent;
    },
    [],
  );

  if (checkIfStatusIs("Delivered")) {
    return (
      <DecisionButton
        id={"start-review-tooltip"}
        tooltip={deliveredTooltip}
        onClick={setStatusTo("review")}
        buttonName={"Start review"}
        buttonVariant={"info"}
      />
    );
  }

  if (checkIfStatusIs("In Review")) {
    const { isDisabled, content } = validateFor("decision");
    const isMissingAccountNumber =
      content === invalidAccountNumberMsg ? true : false;

    const disallowInadmissible = isDisabled && !isMissingAccountNumber;
    return (
      <>
        <DecisionButton
          key={"accept-tooltip"}
          id={"accept-tooltip"}
          tooltip={getooltip("approve", isDisabled, content)}
          onClick={setStatusTo("accept")}
          buttonName={"Approve"}
          buttonVariant={"success"}
          disabled={isDisabled}
        />
        <DecisionButton
          key={"amend-tooltip"}
          id={"amend-tooltip"}
          tooltip={getooltip("return", isDisabled, content)}
          onClick={() => openModal("amend")}
          buttonName={"Return"}
          buttonVariant={"warning"}
          disabled={isDisabled}
        />
        <DecisionButton
          key={"reject-tooltip"}
          id={"reject-tooltip"}
          tooltip={getooltip("reject", isDisabled, content)}
          onClick={() => openModal("reject")}
          buttonName={"Inadmissible"}
          buttonVariant={"danger"}
          disabled={disallowInadmissible}
        />
      </>
    );
  }

  if (checkIfStatusIs("Approved")) {
    const validateSync = validateFor("sync");
    return (
      <>
        <AuthGuard roles={["admin", "revertApproval"]}>
          <DecisionButton
            id={"start-review-tooltip"}
            tooltip={returnTooltip}
            onClick={setStatusTo("Delivered")}
            buttonName={"Return to Delivered"}
            buttonVariant="warning"
          />
        </AuthGuard>
        <DecisionButton
          id={"sync-tooltip"}
          hidden={validateSync.isHidden}
          tooltip={validateSync.content}
          onClick={setStatusTo("sync")}
          buttonName={"Sync"}
          buttonVariant={"danger"}
          disabled={validateSync.isDisabled}
        />
        <NCPTSSyncButton refreshAccount={refreshAccount} />
      </>
    );
  }

  return null;
};

export default DecisionButtonsContainer;

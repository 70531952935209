import { useCallback } from "react";
import { processFormName } from "@taxscribe/utils";
import useJurisdictionContext from "./useJurisdictionContext";

const useFormNameProcess = () => {
  const { jurisdiction } = useJurisdictionContext();
  return useCallback(
    (shortName: string) => {
      return processFormName({ shortName, stateAbbr: jurisdiction.stateAbbr });
    },
    [jurisdiction.stateAbbr],
  );
};

export default useFormNameProcess;

import React, { FC } from "react";
import styled from "styled-components";

const TotalWrapper = styled.div`
  padding-left: 1rem;
`;

interface TotalCountProps {
  count: number;
  limit: number;
}

const TotalCount: FC<TotalCountProps> = ({ count, limit }) => {
  return (
    <TotalWrapper>
      Viewing {Math.min(limit, count)} of {count} results
    </TotalWrapper>
  );
};

export default TotalCount;

import { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";

import useJurisdictionContext from "./useJurisdictionContext";

export const GET_JURISDICTION_FORM_TYPES = gql`
  query GetJurisdictionFormTypes(
    $stateAbbr: String!
    $jurisdictionName: String!
    $jurisdictionType: String!
  ) {
    jurisdiction(
      stateAbbr: $stateAbbr
      jurisdictionName: $jurisdictionName
      jurisdictionType: $jurisdictionType
    ) {
      formTypes {
        id
        shortName
        longName
      }
    }
  }
`;

export interface GetJurisdictionFormTypesReturn {
  jurisdiction: {
    formTypes: {
      id: number;
      shortName: "Extension" | "Listing" | "Exemption";
      longName: string;
    }[];
  };
}

const useJurisdictionFormTypes = () => {
  const { jurisdiction } = useJurisdictionContext();
  const { stateAbbr, name, jurisdictionType } = jurisdiction || {};
  const { data, loading, error } = useQuery<GetJurisdictionFormTypesReturn>(
    GET_JURISDICTION_FORM_TYPES,
    {
      variables: { stateAbbr, jurisdictionName: name, jurisdictionType },
    },
  );

  const formTypes = useMemo(() => {
    if (!data?.jurisdiction) return [];
    return data.jurisdiction.formTypes
      .map((ft) => ({
        id: ft.id,
        name: ft.shortName,
      }))
      .sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
  }, [data]);

  return {
    formTypes,
    error: error,
    loading: loading,
  };
};

export default useJurisdictionFormTypes;

import baseApiConfigs from "../config/api";
const { baseUrl, version, getAuthToken } = baseApiConfigs;

export interface BizworksFetchOptions {
  endpoint: string;
  method: string;
  headers?: any;
  body?: any;
  queryParameters?: string;
}

const bizworksFetch = async <ReturnData = any>({
  endpoint,
  method,
  headers,
  body,
  queryParameters = "",
}: BizworksFetchOptions) => {
  try {
    const authToken = await getAuthToken();
    const response = await fetch(
      `${baseUrl}/${version}/${endpoint}?${queryParameters}`,
      {
        method,
        headers: {
          Authorization: authToken,
          ...headers,
        },
        body,
      },
    );
    const data = await response.json() as ReturnData;

    return {
      data,
      headers: response.headers,
    };
  } catch (err) {
    console.error(err);
    return { error: err };
  }
};

export default bizworksFetch;

import React, { FC, PropsWithChildren } from "react";
import useJurisdictionContext from "../../hooks/useJurisdictionContext";

export interface StateGuardProps {
  states: string[];
}

const StateGuard: FC<PropsWithChildren<StateGuardProps>> = ({
  children,
  states,
}) => {
  const { jurisdiction } = useJurisdictionContext();
  if (states.includes(jurisdiction.stateAbbr)) {
    return <>{children}</>;
  }

  return null;
};

export default StateGuard;

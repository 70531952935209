import { FC, useCallback } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useAppModalContext } from "@taxscribe/ui";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { sendAttachments } from "../api/bizworks";
import { Body, Footer } from "./Modal/AttachmentsContainer";
import { Account } from "../utils/Interfaces";

const SendAttachmentsBtn = styled(Button)`
  margin-top: 5px;
`;

interface AttachmentContainerProps {
  account: Account;
}

const HoverCount = styled.span`
  position: relative;
  left: 3px;
  top: -5px;
`;

const AttachmentContainer: FC<AttachmentContainerProps> = ({ account }) => {
  const { addModal, removeModal } = useAppModalContext();
  const { formId } = useParams();

  const closeModal = useCallback(() => {
    removeModal();
  }, [removeModal]);

  const showModal = useCallback(() => {
    const modalProps = {
      header: "Preparing Attachments",
      body: <Body />,
      footer: <Footer closeModal={closeModal} />,
    };
    addModal(modalProps);
  }, [addModal, closeModal]);

  const handleSendAttachments = useCallback(async () => {
    const response = await sendAttachments(Number(formId));
    if (response && response.ok) {
      showModal();
    }
  }, [formId, showModal]);

  const getToolTip = useCallback((isDisabled: boolean) => {
    const text = isDisabled ?
      "No attachments available" :
      "Click to send attachments";
    return <Tooltip id="tooltip-top">{text}</Tooltip>;
  }, []);

  if (account.formType === "Extension") return null;

  return (
    <>
      <OverlayTrigger
        key="top"
        placement="top"
        overlay={getToolTip(!account.haveAttachments)}
      >
        <span>
          <SendAttachmentsBtn
            variant="dark"
            onClick={handleSendAttachments}
            disabled={!account.haveAttachments}
          >
            <FontAwesomeIcon
              icon={faDownload}
              className="me-2"
            />
            Available Attachments
            <HoverCount>{account?.attachmentsCount || 0}</HoverCount>
          </SendAttachmentsBtn>
        </span>
      </OverlayTrigger>
    </>
  );
};

export default AttachmentContainer;

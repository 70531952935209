import { FC, useState, useEffect, useCallback } from "react";
import { Card, ListGroup } from "react-bootstrap";

import TableHeader from "./TableHeader";
import PageLimiter from "../../PageLimiter";
import TableBody from "./TableBody";
import { pageLimitChoices, getSyncRequests } from "../../../api/bizworks";
import { DropdownOptions } from "../../../utils/Interfaces";

interface SyncRequestsKeys {
  id: number;
  formId: number;
  documentUuid: string;
  accountNumberPrimary: string;
  statusCode: string;
  message: string;
  createdat: string;
}

const SyncRequests: FC = () => {
  const [data, setData] = useState<Array<SyncRequestsKeys>>([]);
  const [pageLimit, setPageLimit] = useState(10);

  const setRequestsData = useCallback(async () => {
    const response = await getSyncRequests(pageLimit);
    if (Array.isArray(response) && response.length !== 0) {
      setData(response);
    }
  }, [pageLimit]);

  const handlePageLimit = useCallback(
    (key: number) => {
      if (pageLimit !== key) {
        setPageLimit(key);
      }
    },
    [pageLimit],
  );

  const handlePageChoices = useCallback(
    () =>
      pageLimitChoices.map(
        (choice: string): DropdownOptions => ({
          value: choice,
          text: choice.toString(),
          active: pageLimit === Number(choice),
        }),
      ),
    [pageLimit],
  );
  const currentPageLimitChoices = handlePageChoices();

  useEffect(() => {
    setRequestsData();
    const interval = setInterval(() => {
      (async () => {
        setRequestsData();
      })();
    }, 5000);
    return () => clearInterval(interval);
  }, [setRequestsData]);

  return (
    <>
      <Card>
        <ListGroup
          variant="flush"
          className="border-top-0"
        >
          <TableHeader />
          <TableBody data={data} />
        </ListGroup>
      </Card>
      <PageLimiter
        pageLimit={pageLimit}
        currentPageLimitChoices={currentPageLimitChoices}
        handlePageLimit={handlePageLimit}
      />
    </>
  );
};

export default SyncRequests;

import { FC, useCallback, useMemo } from "react";
import { Nav, Dropdown } from "react-bootstrap";
import styled from "styled-components";
import { faCircleChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CustomDropdown from "../CustomDropdown";
import { noop } from "lodash";

const StyledAssignBtn = styled(CustomDropdown)`
  .btn {
    background-color: #146ebe;
    border-color: #146ebe;
  }
  .btn:hover {
    background-color: #177bd5;
    border-color: #177bd5;
  }
  .dropdown-toggle::after,
  .dropdown-toggle::before {
    display: none;
  }
`;

interface AssignButtonProps {
  assignedUsers: Array<any>;
  onSelectAssigned: Function;
  checkedForms: Array<any>;
  dropdownVariant?: boolean;
  bulkActionSelected?: string | null;
  setBulkActionSelected?: Function;
}

const AssignButton: FC<AssignButtonProps> = ({
  assignedUsers,
  onSelectAssigned,
  checkedForms,
  dropdownVariant = false,
  bulkActionSelected = null,
  setBulkActionSelected = noop,
}) => {
  const isSelected = bulkActionSelected === "assign";
  const setAction = useCallback(() => {
    if (isSelected) {
      setBulkActionSelected(null);
    } else {
      setBulkActionSelected("assign");
    }
  }, [isSelected, setBulkActionSelected]);

  const items = useMemo(() => {
    return assignedUsers.filter(({ show }) => show);
  }, [assignedUsers]);

  if (dropdownVariant) {
    return (
      <Dropdown.Item
        onClick={() => setAction()}
        active={isSelected}
      >
        Assign
      </Dropdown.Item>
    );
  }

  return (
    <Nav className="my-2 my-lg-0 ms-lg-3">
      <StyledAssignBtn
        id="assigned-records"
        text={
          <span>
            Assign{" "}
            <FontAwesomeIcon
              icon={faCircleChevronDown}
              className="ms-1"
            />
          </span>
        }
        items={items}
        onSelect={onSelectAssigned}
        disabled={checkedForms.length === 0}
        dropDirection="start"
        variant="primary"
      />
    </Nav>
  );
};

export default AssignButton;

import { FC } from "react";
import { Row, Col, Spinner } from "react-bootstrap";

export interface LoadingPlaceholderProps {
  placeholderLocation: string;
}

const LoadingPlaceholder: FC<LoadingPlaceholderProps> = ({
  placeholderLocation,
}) => {
  if (localStorage.getItem("DEBUG") === "TRUE") {
    console.info("loading placeholder:", placeholderLocation);
  }

  return (
    <Row>
      <Col className="d-flex justify-content-center mt-5">
        <Spinner
          animation="border"
          variant="secondary"
          role="status"
          style={{ height: "5rem", width: "5rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Col>
    </Row>
  );
};

export default LoadingPlaceholder;

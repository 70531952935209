import { FormProvider, NCIPPListing } from "@taxscribe/form";
import createFlatFormView from "./createFlatFormView";

const NCIPPListingView = createFlatFormView({
  noPdf: true,
  includeNcptsDebug: true,
  Provider: NCIPPListing.Provider as FormProvider,
  navLinks: NCIPPListing.navLinks,
});

export default NCIPPListingView;

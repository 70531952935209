import React, { FC, ReactNode } from "react";
import { Card } from "react-bootstrap";

interface SingleNavItem {
  Element: FC;
  path: string;
  text: string;
}

interface MultiNavItem {
  childRoutes: SingleNavItem[];
  text: string;
}

type NavItem = MultiNavItem | SingleNavItem;

interface LinkViewProps {
  link: NavItem;
}

const isSingleNavItem = (link: NavItem): link is SingleNavItem => {
  return (link as SingleNavItem).Element !== undefined;
};

const LinkView: FC<LinkViewProps> = ({ link }) => {
  if (isSingleNavItem(link)) {
    return (
      <div key={link.path}>
        <br />
        <h1>{link.text}</h1>
        <link.Element/>
        <hr />
      </div>
    );
  }

  return (
    <>
      {link.childRoutes.map((childLink) => {
        return <LinkView link={childLink} key={childLink.text}/>;
      })}
    </>
  );
};


interface FlatFormViewProps {
  navLinks: NavItem[];
  extraContent?: ReactNode | null;
}

const ExtraContentWrapper: FC<Pick<FlatFormViewProps, "extraContent">> = ({
  extraContent,
}) => {
  if (!extraContent) {
    return null;
  }

  return <div>{extraContent}</div>;
};

const FlatFormView: FC<FlatFormViewProps> = ({
  navLinks,
  extraContent,
}) => {
  return (
    <Card>
      <Card.Body>
        <ExtraContentWrapper extraContent={extraContent}/>
        {navLinks.map((link) => {
          return <LinkView link={link} key={link.text}/>;
        })}
      </Card.Body>
    </Card>
  );
};

export default FlatFormView;

import { FC, useMemo } from "react";

import useJurisdictionContext from "../../hooks/useJurisdictionContext";
import SyncButton from "./SyncButton";
import ApproveButton from "./ApproveButton";
import AssignButton from "./AssignButton";
import DropdownActions from "./DropdownActions";
import jurisdictionConfigMap from "./validations/jurisdictionConfig";

interface BulkButtonsProps {
  formType: string;
  checkedForms: Array<any>;
  assignActionProps: any;
  changeStatusActionProps: any;
  bulkActionSelected: string | null;
  setBulkActionSelected: Function;
  statusFilter: string | null;
}

const BulkButtons: FC<BulkButtonsProps> = ({
  formType,
  checkedForms,
  assignActionProps,
  changeStatusActionProps,
  bulkActionSelected,
  setBulkActionSelected,
  statusFilter,
}) => {
  const { jurisdiction } = useJurisdictionContext();
  const { stateAbbr } = jurisdiction || {};

  const { showApprove, showSync } = useMemo(() => {
    const stateConfig = jurisdictionConfigMap[stateAbbr];
    if (stateConfig) return stateConfig;
    return jurisdictionConfigMap.default;
  }, [stateAbbr]);

  const showApproveAction = showApprove.includes(formType);
  const showSyncAction = showSync.includes(formType);
  const showAssignAction = true;

  if (!showApproveAction && !showSyncAction && !showAssignAction) {
    return null;
  }

  return (
    <>
      {bulkActionSelected === "approve" ? (
        <ApproveButton
          checkedForms={checkedForms}
          onClick={changeStatusActionProps.onClick}
        />
      ) : null}
      {bulkActionSelected === "sync" ? (
        <SyncButton
          checkedForms={checkedForms}
          onClick={changeStatusActionProps.onClick}
          statusFilter={statusFilter}
        />
      ) : null}
      {bulkActionSelected === "assign" ? (
        <AssignButton
          checkedForms={checkedForms}
          assignedUsers={assignActionProps.assignedUsers}
          onSelectAssigned={assignActionProps.onSelectAssigned}
        />
      ) : null}
      <DropdownActions
        assignActionProps={assignActionProps}
        changeStatusActionProps={changeStatusActionProps}
        checkedForms={checkedForms}
        showApproveAction={showApproveAction}
        showSyncAction={showSyncAction}
        showAssignAction={showAssignAction}
        bulkActionSelected={bulkActionSelected}
        setBulkActionSelected={setBulkActionSelected}
        statusFilter={statusFilter}
      />
    </>
  );
};

export default BulkButtons;

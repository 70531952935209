import { FC } from "react";
import { ListGroup, Row, Col } from "react-bootstrap";

const TableHeader: FC = () => {
  const headers = [
    { str: "Name", length: 4 },
    { str: "Email", length: 4 },
    { str: "Active / Inactive", length: 4 },
  ];
  return (
    <ListGroup.Item>
      <Row className="p-3 listing-row">
        {headers.map((header, i) => {
          return (
            <Col
              key={i}
              xs={header.length}
              className="d-flex align-items-center flex-column flex-xl-row text-xl-start p-lg-0 fw-bold"
            >
              {header.str}
            </Col>
          );
        })}
      </Row>
    </ListGroup.Item>
  );
};

export default TableHeader;

import { noop } from "lodash";
import { FC } from "react";
import { Nav, Button, Dropdown } from "react-bootstrap";

interface ApproveButtonProps {
  onClick: Function;
  checkedForms: Array<any>;
  dropdownVariant?: boolean;
  bulkActionSelected?: string | null;
  setBulkActionSelected?: Function;
}

const ApproveButton: FC<ApproveButtonProps> = ({
  onClick,
  checkedForms,
  dropdownVariant = false,
  bulkActionSelected = null,
  setBulkActionSelected = noop,
}) => {
  const isSelected = bulkActionSelected === "approve";
  const setAction = () => {
    if (isSelected) {
      setBulkActionSelected(null);
    } else {
      setBulkActionSelected("approve");
    }
  };

  if (dropdownVariant) {
    return (
      <Dropdown.Item
        onClick={() => setAction()}
        active={isSelected}
      >
        Approve
      </Dropdown.Item>
    );
  }

  return (
    <Nav className="my-2 my-lg-0 ms-lg-3">
      <Button
        variant="success"
        type="button"
        onClick={() => onClick("Approve")}
        disabled={checkedForms.length === 0}
      >
        Approve
      </Button>
    </Nav>
  );
};

export default ApproveButton;

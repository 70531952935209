import { createContext } from "react";

/* eslint-disable camelcase */
interface JurisdictionProps {
  id: number | null;
  name: string;
  stateAbbr: string;
  jurisdictionType: string;
  tmaCid: number | null;
}

interface JurisdictionContextProps {
  jurisdiction: JurisdictionProps;
}

export const initialState = {
  id: null,
  name: "",
  stateAbbr: "",
  jurisdictionType: "",
  tmaCid: null,
};

export const JurisdictionContext = createContext<JurisdictionContextProps>({
  jurisdiction: initialState,
});

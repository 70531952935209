import React, { FC } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import styled from "styled-components";
import useDownloadTaxFormExport from "../../../hooks/useDownloadTaxFormExports";
import useLookupExports, {
  JurisdictionExport,
} from "../../../hooks/useLookupExports";

const RightAlignedTD = styled.td`
  display: flex;
  justify-content: end;
`;

const StyledTable = styled(Table)`
  margin-bottom: 0px;
`;

const StyledSpinner = styled(Spinner)`
  max-height: 1rem;
  max-width: 1rem;
`;

const ExportsTableRow: FC<JurisdictionExport> = ({ name }) => {
  const [download, { loading }] = useDownloadTaxFormExport(name);
  // eslint-disable-next-line
  const [jurId, year, fileName] = name.split("/");
  const buttonContent = loading ? <StyledSpinner animation="border" /> : "Download";
  return (
    <tr>
      <td>{year}</td>
      <td>{fileName}</td>
      <RightAlignedTD>
        <Button
          variant="dark"
          onClick={download}
        >
          {buttonContent}
        </Button>
      </RightAlignedTD>
    </tr>
  );
};

const emptyExports: JurisdictionExport[] = [];

export interface ExportsTableProps {
  filingYear?: number;
}

const ExportsTable: FC<ExportsTableProps> = ({ filingYear }) => {
  const { data, loading } = useLookupExports(filingYear);
  const jurExports = data?.jurisdiction?.taxFormExports || emptyExports;

  if (loading) return <Spinner animation="border" />;

  return (
    <StyledTable hover striped>
      <thead>
        <tr>
          <th>Year</th>
          <th>File Name</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {jurExports.map((taxFormExport) => {
          return (
            <ExportsTableRow
              {...taxFormExport}
              key={taxFormExport.name}
            />
          );
        })}
      </tbody>
    </StyledTable>
  );
};

export default ExportsTable;

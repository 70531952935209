import React, { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import { SUBContextProvider } from "@reasongcp/react-fire-sub";
import { TaxbaseApolloProvider } from "@taxscribe/apollo-helpers";
import {
  AppToastProvider,
  AppToasts,
  ToastComponentProps,
  AppModalProvider,
  AppModal,
} from "@taxscribe/ui";

import config from "./config";
import AuthBoundary from "./components/shared/AuthBoundary";
import UserContextProvider from "./components/shared/UserContextProvider";
import UserListContextProvider from "./components/shared/UserListContextProvider";
import JurisdictionContextProvider from "./components/shared/JurisdictionContextProvider";
import AppLayout from "./components/shared/AppLayout";

import Success from "./components/shared/Toasts/Success";
import Error from "./components/shared/Toasts/Error";
import Warning from "./components/shared/Toasts/Warning";
import Danger from "./components/shared/Toasts/Danger";

const useFirebaseEmulator = config.releaseStage === "development";

const toastComponents: Record<string, FC<ToastComponentProps>> = {
  success: Success,
  error: Error,
  warning: Warning,
  dander: Danger,
};

const cacheConfig = {
  typePolicies: {
    TaxFormMessage: {
      keyFields: ["id", "source"],
    },
    Pdf: {
      keyFields: ["url"],
    },
  },
};

const App = () => {
  return (
    <AppToastProvider
      defaultTimeout={10000}
      position="top-end"
      ToastComponents={toastComponents}
    >
      <AppModalProvider>
        <SUBContextProvider
          emulate={useFirebaseEmulator}
          config={config.firebaseConfig}
          authCookieName={config.authCookieName}
        >
          <TaxbaseApolloProvider
            uri={config.taxbaseApi}
            cacheConfig={cacheConfig}
          >
            <BrowserRouter>
              <AuthBoundary>
                <UserContextProvider>
                  <JurisdictionContextProvider>
                    <UserListContextProvider>
                      <AppLayout />
                    </UserListContextProvider>
                    <AppModal />
                  </JurisdictionContextProvider>
                </UserContextProvider>
              </AuthBoundary>
            </BrowserRouter>
          </TaxbaseApolloProvider>
        </SUBContextProvider>
      </AppModalProvider>
      <AppToasts />
    </AppToastProvider>
  );
};

export default App;

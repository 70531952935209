import { useState, useEffect, FC, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Navbar, Nav } from "react-bootstrap";

import { getAccountDetails } from "../../../api/bizworks";
import AccountInformation from "../../AccountInformation";
import HistoryContainer from "../../HistoryContainer";
import DecisionContainer from "../../DecisionContainer";
import AttachmentContainer from "../../AttachmentsContainer";
import MessageContainer from "../../MessageContainer";

import useUserContext from "../../../hooks/useUserContext";
import useUserListContext from "../../../hooks/useUserListContext";
import { Account } from "../../../utils/Interfaces";
import ViewForm from "./ViewForm";
import ErrorPlaceholder from "../../shared/ErrorPlaceholder";
import { noop } from "lodash";

const Form: FC = () => {
  // TODO: Make this use the UUID?
  const { formId } = useParams();
  const { user } = useUserContext();
  const { userList = [] } = useUserListContext();

  const [account, setAccount] = useState<Account | null>(null);

  const refreshAccount = useCallback(async () => {
    const accountDetails = await getAccountDetails(Number(formId));
    setAccount(accountDetails);
  }, [formId]);

  useEffect(() => {
    refreshAccount();
  }, [refreshAccount]);

  if (!account || !user) {
    return null;
  }

  if (!user.active) {
    const msg = "This user is inactive; action is forbidden.";
    return (
      <ErrorPlaceholder
        msg={msg}
        redirect={"/"}
      />
    );
  }

  return (
    <>
      <Navbar
        id="header-nav"
        expand="lg"
        className="bg-white sticky-top border-bottom mb-3 justify-content-start justify-content-lg-between flex-wrap"
      >
        <AccountInformation
          account={account}
          userList={userList}
          currentUser={user}
          setUpdateValues={noop}
          refreshAccount={refreshAccount}
        />
        <Nav>
          <Nav.Item className="d-flex align-items-center mt-lg-3">
            <MessageContainer unreadCount={account.unreadCount}/>
          </Nav.Item>
          <Nav.Item className="d-flex align-items-center mt-lg-3">
            <HistoryContainer account={account} />
          </Nav.Item>
        </Nav>
      </Navbar>
      <Row className="justify-content-between mb-3">
        <Col>
          <DecisionContainer
            account={account}
            refreshAccount={refreshAccount}
            currentUser={user}
          />
        </Col>
        <Col className="text-end">
          <AttachmentContainer account={account} />
        </Col>
      </Row>
      <Row>
        <Col className="mb-3">
          <ViewForm extensionId={account.extensionId} />
        </Col>
      </Row>
    </>
  );
};

export default Form;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const aircraftEntries = [
    [
        { key: "year", label: "Year" },
        { key: "body", label: "Body" },
        { key: "make", label: "Make" },
    ],
    [
        { key: "model", label: "Model" },
        { key: "yearAcquired", label: "Year Acquired" },
        { key: "cost", label: "Acquisition Cost" },
    ],
    [
        { key: "airport", label: "Airport" },
        { key: "hangarNumber", label: "Hangar Number" },
        { key: "tailNumber", label: "Tail Number" },
    ],
];
exports.default = aircraftEntries;

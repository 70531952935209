import { NCIPPListingData } from "@taxscribe/form-data-types";
import { CurrentSubmission } from "@taxscribe/gql-queries";
import printIppReview from "@taxscribe/ipp-printer";
import React, { FC, useCallback, useMemo } from "react";
import { Button } from "react-bootstrap";
import useJurisdictionForm from "../../../hooks/useJurisdictionForm";

const NCIPPPrintButton: FC = () => {
  const { data } = useJurisdictionForm();
  const form = data?.jurisdiction?.form;
  const metadata = useMemo(() => {
    return form?.currentSubmission || ({} as Partial<CurrentSubmission>);
  }, [form]);

  const handleClick = useCallback(() => {
    if (!form) return;
    printIppReview(form.data as NCIPPListingData, metadata);
  }, [form, metadata]);

  if (form?.shortName === "IPP Listing") {
    return (
      <Button
        onClick={handleClick}
        variant="light"
      >
        Get Form Summary
      </Button>
    );
  }

  return null;
};

export default NCIPPPrintButton;

import { useMostRecentFormPeriod } from "@taxscribe/ui";
import React, { FC, PropsWithChildren } from "react";
import useJurisdictionContext from "../../../hooks/useJurisdictionContext";
import PDFView from "../../pages/Form/PDFView";
import { FormPeriod } from "../../pages/Form/queries/returnTypes";

export interface ShowPDFOrFormProps {
  formPeriod: FormPeriod;
  noPdf?: boolean;
}

const ShowPDFOrForm: FC<PropsWithChildren<ShowPDFOrFormProps>> = ({
  noPdf,
  children,
  formPeriod,
}) => {
  const { jurisdiction } = useJurisdictionContext();
  const { data, loading, called } = useMostRecentFormPeriod({
    jurisdictionId: jurisdiction.id,
    formTypeId: formPeriod.formTypeId,
  });

  if (!called || loading) {
    return <div>Loading...</div>;
  }

  if (
    !noPdf &&
    data?.mostRecentFormPeriod?.filingYear !== formPeriod.filingYear
  ) {
    return <PDFView />;
  }

  return <>{children}</>;
};

export default ShowPDFOrForm;

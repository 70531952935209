import { FC, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";

interface ConfirmationBodyModalProps {
  jobId: number;
}

export const BodyConfirmation: FC<ConfirmationBodyModalProps> = ({ jobId }) => {
  return (
    <div>
      <p>Your job has been initialized and will complete processing soon.</p>
      <p>Job ID: {jobId}</p>
    </div>
  );
};

interface ConfirmationFooterModalProps {
  closeModal: Function;
  navigate: Function;
}

export const FooterConfirmation: FC<ConfirmationFooterModalProps> = ({
  navigate,
  closeModal,
}) => {
  const handleConfirmation = useCallback(() => {
    closeModal();
    navigate("/jobs");
  }, [navigate, closeModal]);

  return (
    <Row>
      <Col>
        <Button
          variant="success"
          onClick={handleConfirmation}
          className="justify-content-lg-end"
        >
          Confirm
        </Button>
      </Col>
    </Row>
  );
};

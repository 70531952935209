import { FC } from "react";
import { Row, Col } from "react-bootstrap";
import CustomDropdown from "./CustomDropdown";
import { DropdownOptions } from "../utils/Interfaces";

interface PageLimiterComponent {
  pageLimit: number;
  currentPageLimitChoices: DropdownOptions[];
  handlePageLimit: Function;
}

const PageLimiter: FC<PageLimiterComponent> = ({
  pageLimit,
  currentPageLimitChoices,
  handlePageLimit,
}) => (
  <Row className="mt-1 p-0">
    <Col className="d-flex align-items-end">
      <h2 className="fs-6 text-uppercase ls-1 mr-1">Page Limit</h2>
      <CustomDropdown
        id="currentPageLimit"
        text={pageLimit.toString()}
        variant="outline-dark"
        items={currentPageLimitChoices}
        onSelect={handlePageLimit}
        className="d-flex col ml-1"
      />
    </Col>
  </Row>
);

export default PageLimiter;

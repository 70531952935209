"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vehiclePermanentPlateEntries = [
    [
        { key: "year", label: "Year" },
        { key: "make", label: "Make" },
        { key: "model", label: "Model" },
    ],
    [
        { key: "body", label: "Body" },
        { key: "vin", label: "VIN" },
        { key: "yearAcquired", label: "Year Acquired" },
    ],
    [
        { key: "cost", label: "Acquisition Cost" },
        { key: "length", label: "Length" },
        { key: "width", label: "Width" },
    ],
    [
        { key: "plateNumber", label: "Plate Number" },
        { key: "titleNumber", label: "Title Number" },
        { key: "regisrationIssueDate", label: "Registration Issue Date" },
    ],
];
exports.default = vehiclePermanentPlateEntries;

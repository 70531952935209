import { createContext } from "react";
import { AssigneesWithDisplayName } from "../utils/Interfaces";

interface IUserListContextProps {
  userList: Array<AssigneesWithDisplayName>;
}

export const UserListContext = createContext<IUserListContextProps>({
  userList: [],
});

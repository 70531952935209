import { FC } from "react";
import { Row, Col, ListGroup, Card } from "react-bootstrap";
import styled from "styled-components";

import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

const PageContainer = styled.div`
  margin: 30px;
`;

interface UserActiveProps {
  show: boolean;
}
const UserActive: FC<UserActiveProps> = ({ show }) => {
  if (!show) return null;

  return (
    <PageContainer>
      <Row className="mb-2">
        <Col>
          <h2>Manage Active Users</h2>
          <p className="text-muted">
            Setting a user to inactive will prevent them from appearing the
            assignee dropdown, however they will still have access to TaxScribe
            Government.
          </p>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h4>Available Users</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <ListGroup
              variant="flush"
              className="border-top-0"
            >
              <TableHeader />
              <TableBody />
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default UserActive;

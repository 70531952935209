import React, { FC, useMemo } from "react";
import { FormProvider, Metadata, NavMapping } from "@taxscribe/form";
import { FormDataUnion, Submission } from "@taxscribe/gql-queries";
import { FormPeriod } from "../queries/returnTypes";
import FlatFormView from "../../../shared/FlatFormView";
import ShowPDFOrForm from "../../../shared/ShowPDFOrForm";
import NCPTSDebugHelper from "../../../shared/NCPTSDebugHelper";

export interface StandardFlatFormViewProps {
  formPeriod: FormPeriod;
  formState: FormDataUnion;
  metadata: Partial<Submission> & Metadata;
  extensionId?: number | null;
}

const DebugWrapper: FC<{
  includeNcptsDebug?: boolean;
}> = ({ includeNcptsDebug }) => {
  if (includeNcptsDebug) {
    return <NCPTSDebugHelper />;
  }

  return null;
};

export interface CreateFlatFormViewArgs {
  noPdf?: boolean,
  Provider: FormProvider,
  navLinks: NavMapping[],
  ExtraContent?: FC<StandardFlatFormViewProps>;
  includeNcptsDebug?: boolean,
}

const createFlatFormView = ({
  noPdf,
  Provider,
  navLinks,
  ExtraContent,
  includeNcptsDebug,
} : CreateFlatFormViewArgs) => {
  const StandardFlatFormView: FC<StandardFlatFormViewProps> = (props) => {
    const {
      metadata,
      formState,
      formPeriod,
    } = props;

    const extraContent = useMemo(() => {
      if (!ExtraContent) {
        return null;
      }

      return <ExtraContent {...props} />;
    }, [props]);

    const data = formState as any;
    return (
      <ShowPDFOrForm noPdf={noPdf} formPeriod={formPeriod}>
        <Provider
          readOnly
          metadata={metadata}
          initialState={data}
        >
          <FlatFormView
            navLinks={navLinks}
            extraContent={extraContent}
          />
          <DebugWrapper includeNcptsDebug={includeNcptsDebug} />
        </Provider>
      </ShowPDFOrForm>
    );
  };

  return StandardFlatFormView;
};

export default createFlatFormView;

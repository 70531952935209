import React, { FC, useEffect } from "react";
import styled from "styled-components";
import useJurisdictionFormPdf from "../../../../hooks/useJurisdictionFormPdf";

const PDFWrapper = styled.div`
  width: 100%;
  height: 75vh;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const PDFView: FC = () => {
  const [getPdfUrl, { loading, data, called }] = useJurisdictionFormPdf();

  useEffect(() => {
    if (!called) getPdfUrl();
  }, [called, getPdfUrl]);

  if (loading) return null;
  return (
    <PDFWrapper>
      <iframe
        title="pdf"
        src={data?.jurisdiction?.form?.currentSubmission?.pdf?.url}
      />
    </PDFWrapper>
  );
};

export default PDFView;

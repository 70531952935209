import { FC } from "react";
import { Col, Row, ListGroup } from "react-bootstrap";
import useUserListContext from "../../../../hooks/useUserListContext";

import UserSelector from "./UserSelector";

const TableBody: FC = () => {
  const { userList = [] } = useUserListContext();

  if (!userList.length) {
    return (
      <ListGroup.Item>
        <Row className="p-3 d-flex align-items-center listing-row">
          <Col className="d-flex flex-column flex-xl-row text-center text-xl-center p-lg-0 justify-content-center">
            There are no users.
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  return (
    <>
      {userList.map((user, idx) => {
        const { email, contactName, displayName } = user;
        return (
          <ListGroup.Item key={idx}>
            <Row className="p-3 d-flex align-items-center listing-row">
              <Col
                xs={4}
                className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0"
              >
                {displayName || contactName}
              </Col>
              <Col
                xs={4}
                className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0 text-capitalize"
              >
                {email}
              </Col>
              <Col
                xs={4}
                className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0 text-capitalize"
              >
                <UserSelector user={user} />
              </Col>
            </Row>
          </ListGroup.Item>
        );
      })}
    </>
  );
};

export default TableBody;

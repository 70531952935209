import { FC } from "react";
import useJurisdictionForm from "../../../hooks/useJurisdictionForm";
import NCPTSButton from "./NCPTSButton";

const ncptsForms = ["IPP Listing", "Listing"];

interface NCPTSSyncButtonProps {
  refreshAccount: () => void;
}

const NCPTSSyncButton: FC<NCPTSSyncButtonProps> = ({
  refreshAccount,
}) => {
  const { data, loading } = useJurisdictionForm();

  const form = data?.jurisdiction?.form;
  const stateAbbr = data?.jurisdiction?.stateAbbr;
  const formType = form?.shortName;
  const ddbKey = form?.ddbKey;
  const accountNumber = form?.accountNumberPrimary;
  const invalidAccountNumber =
    (accountNumber || "").length < 1 || accountNumber === "N/A";
  const isSyncableForm = ncptsForms.includes(formType || "");

  if (
    loading ||
    !ddbKey ||
    !formType ||
    !isSyncableForm ||
    invalidAccountNumber ||
    stateAbbr?.toLocaleUpperCase() !== "NC"
  ) {
    return null;
  }

  return (
    <NCPTSButton
      ddbKey={ddbKey}
      formType={formType}
      refreshAccount={refreshAccount}
    />
  );
};

export default NCPTSSyncButton;

import { FC, useState, useMemo, useEffect } from "react";
import { UserListContext } from "../../utils/UserListContext";
import { getAssignees } from "../../api/bizworks";
import { AssigneesObj, AssigneesWithDisplayName } from "../../utils/Interfaces";
import { useCurrentOrgMembers } from "@reasongcp/react-fire-sub";
import getUsersData from "../../utils/getUsersData";

interface UserListContextProviderProps {
  children: any;
}

const UserListContextProvider: FC<UserListContextProviderProps> = ({
  children,
}) => {
  const members = useCurrentOrgMembers();
  const [userList, setUserList] = useState<Array<AssigneesWithDisplayName>>([]);
  const userListProvider = useMemo(
    () => ({ userList, setUserList }),
    [userList, setUserList],
  );

  useEffect(() => {
    (async () => {
      const assigneesData: Array<AssigneesObj> = await getAssignees();
      const userData = getUsersData(assigneesData, members);
      setUserList(userData);
    })();
  }, [members]);

  return (
    <UserListContext.Provider value={userListProvider}>
      {children}
    </UserListContext.Provider>
  );
};

export default UserListContextProvider;

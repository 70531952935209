import { FC } from "react";
import { Card } from "react-bootstrap";
import { Account, formWithJobRelated } from "../../../utils/Interfaces";
import FormsTableHeaders from "./FormsTableHeaders";
import FormTableRow from "./FormTableRow";

interface TableComponent {
  results: Array<any>;
  checkedForms: Array<Account>;
  setCheckedForms: Function;
  formsWaitingUpdate: Array<formWithJobRelated>;
  bulkActionSelected: string | null;
}

const FormsTable: FC<TableComponent> = ({
  results,
  checkedForms,
  setCheckedForms,
  formsWaitingUpdate,
  bulkActionSelected,
}) => {
  return (
    <>
      <Card>
        <FormsTableHeaders />
        {results.map((result: Account, index: number) => (
          <FormTableRow
            key={index}
            result={result}
            checkedForms={checkedForms}
            setCheckedForms={setCheckedForms}
            formsWaitingUpdate={formsWaitingUpdate}
            bulkActionSelected={bulkActionSelected}
          />
        ))}
      </Card>
    </>
  );
};

export default FormsTable;

// Inside the array goes the form type where the option needs to be shown
interface JurisdictionConfig {
  showApprove: string[];
  showSync: string[];
  showAssign: string[];
}

const jurisdictionConfigMap: Record<string, JurisdictionConfig> = {
  NC: {
    showApprove: ["Extension"],
    showSync: ["Extension"],
    showAssign: ["Listing", "Extension"],
  },
  MI: {
    showApprove: [],
    showSync: [],
    showAssign: ["Listing", "Exemption"],
  },
  default: {
    showApprove: ["Extension"],
    showSync: ["Extension"],
    showAssign: ["Listing", "Exemption"],
  },
};

export default jurisdictionConfigMap;

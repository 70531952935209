import { gql, useLazyQuery } from "@apollo/client";
import React, { FC, ReactNode, useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHotkeys } from "react-hotkeys-hook";

import { useParams } from "react-router-dom";
import { getNcptsDebugData } from "../../api/bizworks";
import useJurisdictionContext from "../../hooks/useJurisdictionContext";
import useJurisdictionForm from "../../hooks/useJurisdictionForm";

interface DisplayNCPTSDataProps {
  data: unknown;
  header: ReactNode;
}

const GET_SYNC_DATA = gql`
  query SyncPreview($uuid: String, $jurisdictionId: Int) {
    jurisdiction(id: $jurisdictionId) {
      form(uuid: $uuid) {
        syncPreview {
          preview
        }
      }
    }
  }
`;

const DisplayNCPTSData: FC<DisplayNCPTSDataProps> = ({ data, header }) => {
  if (!data) return null;
  return (
    <div>
      <h4>{header}</h4>
      <pre>{JSON.stringify(data, null, 2)}</pre>;
    </div>
  );
};

const NCPTSDebugHelper: FC = () => {
  const { data: jurisdictionForm } = useJurisdictionForm();
  const { formId } = useParams();
  const {
    jurisdiction: { id: jurisdictionId },
  } = useJurisdictionContext();
  const debugMode = window.localStorage.getItem("DEBUG") === "TRUE";
  const [ncptsData, setNcptsData] = useState<unknown>(null);
  const [syncData, setSyncData] = useState<unknown>(null);
  const uuid = jurisdictionForm?.jurisdiction?.form?.ddbKey;
  const [query] = useLazyQuery(GET_SYNC_DATA);

  const getSyncData = useCallback(async () => {
    if (!debugMode) return;
    if (ncptsData) {
      setNcptsData(null);
      return;
    }

    if (syncData) {
      setSyncData(null);
      return;
    }

    const numberId = Number(formId);
    if (isNaN(numberId)) return;

    const data = await getNcptsDebugData(numberId).catch(console.error);
    const sData = await query({ variables: { uuid, jurisdictionId } }).catch(
      console.error,
    );

    setNcptsData(data);
    setSyncData(sData?.data?.jurisdiction?.form?.syncPreview?.preview);
  }, [
    debugMode,
    formId,
    query,
    syncData,
    setNcptsData,
    uuid,
    ncptsData,
    setSyncData,
    jurisdictionId,
  ]);

  useHotkeys(
    "ctrl+alt+n",
    async () => {
      await getSyncData();
    },
    [getSyncData],
  );

  if (!debugMode) return null;
  return (
    <Row>
      <Col sm={6}>
        <DisplayNCPTSData
          data={syncData}
          header="Sync Data"
        />
      </Col>
    </Row>
  );
};

export default NCPTSDebugHelper;

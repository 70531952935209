import { FC, useCallback, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import { useAppToastContext } from "@taxscribe/ui";

import { AssigneesObj } from "../../../../utils/Interfaces";
import { updateUserActive } from "../../../../api/bizworks";

interface TooltipProps {
  isActive: boolean;
}

const UserTooltip: FC<TooltipProps> = ({ isActive }) => (
  <Tooltip id="button-tooltip">{isActive ? "Active" : "Inactive"}</Tooltip>
);

interface UserSelectorProps {
  user: AssigneesObj;
}

const UserSelector: FC<UserSelectorProps> = ({ user }) => {
  const { loginId, active } = user;
  const { addToastMessage } = useAppToastContext();
  const [isActive, setIsActive] = useState(active);

  const handleChange = useCallback(async () => {
    try {
      const userActive = !isActive;
      setIsActive(userActive);
      const response = await updateUserActive(Number(loginId), userActive);
      if (response.ok) {
        addToastMessage({
          type: "success",
          header: "Updated",
          body: `This user is now ${userActive ? "active" : "inactive"}.`,
        });
      } else {
        addToastMessage({
          type: "danger",
          header: "Failed",
          body: response.message,
        });
      }
    } catch (err) {
      addToastMessage({
        type: "danger",
        header: "Failed",
        body: "The attempt to update the data has failed",
      });
    }
  }, [isActive, setIsActive, addToastMessage, loginId]);

  return (
    <Form>
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={<UserTooltip isActive={isActive} />}
      >
        <Form.Check
          type="switch"
          id="custom-switch"
          checked={isActive}
          onChange={handleChange}
        />
      </OverlayTrigger>
    </Form>
  );
};

export default UserSelector;

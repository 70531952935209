import { useAuth, useCurrentOrgMembers } from "@reasongcp/react-fire-sub";
import React, { FC, PropsWithChildren, useMemo } from "react";

interface AuthGuardProps {
  roles: string[];
}

const useUserRoles = () => {
  const user = useAuth();
  const members = useCurrentOrgMembers();
  return useMemo(() => {
    const member = members.find((member) => {
      return member.uid === user?.uid;
    });
    return member?.roles || [];
  }, [members, user]);
};

const AuthGuard: FC<PropsWithChildren<AuthGuardProps>> = ({
  children,
  roles,
}) => {
  const userRoles = useUserRoles();
  const hasRole = userRoles
    .map((role: string) => {
      return roles.includes(role);
    })
    .some(Boolean);

  if (hasRole) return <>{children}</>;
  return null;
};

export default AuthGuard;

import { useMemo } from "react";
import useUserListContext from "./useUserListContext";

export interface UseFullUserDataArgs {
  id?: number;
  uid?: string;
}

const useFullUserData = ({ id, uid }: UseFullUserDataArgs) => {
  const { userList } = useUserListContext();

  return useMemo(() => {
    if (!id && !uid) return null;

    const assignee = userList?.find((u) => {
      const legacyUuidMatch = u.firesub_uuid === uid;
      const uuidMatch = u.uuid === uid;
      const idMatch = u.loginId === id;
      return legacyUuidMatch || idMatch || uuidMatch;
    });

    return {
      assignee,
      displayName: assignee?.displayName || "",
    };
  }, [id, uid, userList]);
};

export default useFullUserData;

import { FC, useState, useMemo, useEffect } from "react";
import { useAuth, useCurrentOrgMembers } from "@reasongcp/react-fire-sub";

import { UserContext } from "../../utils/UserContext";
import LoadingPlaceholder from "./LoadingPlaceholder";
import ErrorPlaceholder from "./ErrorPlaceholder";
import { getCurrentUser } from "../../api/bizworks";
import config from "../../config";

interface UserContextProviderProps {
  children: any;
}

const UserContextPlaceholder = () => <LoadingPlaceholder placeholderLocation="UserContextProvider" />;
const UserContextProvider: FC<UserContextProviderProps> = ({ children }) => {
  const firesubUser = useAuth();
  const members = useCurrentOrgMembers();
  const userDoc = members.find((member) => member.uid === firesubUser?.uid);

  const [user, setUser] = useState<any>(null);
  const userProvider = useMemo(() => ({ user }), [user]);

  useEffect(() => {
    if (firesubUser && userDoc) {
      (async () => {
        const userData = await getCurrentUser();
        const roles = userData.active ? userData.roles : [];
        const userValues = {
          ...userData,
          ...firesubUser,
          roles,
        };
        setUser(userValues);
      })();
    }
  }, [firesubUser, userDoc, setUser]);

  if (!user) {
    return <UserContextPlaceholder />;
  }

  if (!userDoc) {
    const msg = "Please contact TaxScribe support at hello@taxscribe.com";
    return (
      <ErrorPlaceholder
        msg={msg}
        redirect={config.ssoUrl}
      />
    );
  }

  return (
    <UserContext.Provider value={userProvider}>{children}</UserContext.Provider>
  );
};

export default UserContextProvider;

import { FC } from "react";
import {
  Nav,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";

import AssignButton from "./AssignButton";
import ApproveButton from "./ApproveButton";
import SyncButton from "./SyncButton";
import useUserContext from "../../hooks/useUserContext";
import AuthGuard from "../shared/RolesGuard";
import { noop } from "lodash";

const StyledMainDropdown = styled(DropdownButton)`
  .btn {
    background-color: #146ebe;
    border-color: #146ebe;
  }
  .btn:hover {
    background-color: #177bd5;
    border-color: #177bd5;
  }
  .dropdown-toggle::after,
  .dropdown-toggle::before {
    display: none;
  }
`;

const StyledAssignBtn = styled(Dropdown.Item)`
  padding: 0;
`;

interface DropdownActionProps {
  checkedForms: Array<any>;
  changeStatusActionProps: any;
  assignActionProps: any;
  showApproveAction?: boolean;
  showSyncAction?: boolean;
  showAssignAction?: boolean;
  bulkActionSelected: string | null;
  setBulkActionSelected?: Function;
  statusFilter: string | null;
}

const DropdownActions: FC<DropdownActionProps> = ({
  checkedForms,
  changeStatusActionProps,
  assignActionProps,
  showApproveAction = true,
  showSyncAction = true,
  showAssignAction = true,
  bulkActionSelected = null,
  setBulkActionSelected = noop,
  statusFilter,
}) => {
  const { user } = useUserContext();
  const tooltipMsg = user?.active ?
    "Select a bulk action" :
    "This user is inactive; action is forbidden.";

  if (!user) return null;

  return (
    <AuthGuard roles={["admin", "bulk"]}>
      <Nav className="my-2 my-lg-0 ms-lg-3">
        <OverlayTrigger
          overlay={<Tooltip id="button-tooltip">{tooltipMsg}</Tooltip>}
        >
          <StyledMainDropdown
            title="Bulk Actions"
            autoClose="outside"
            disabled={!user.active}
          >
            {showApproveAction ? (
              <ApproveButton
                checkedForms={checkedForms}
                onClick={changeStatusActionProps.onClick}
                dropdownVariant={true}
                bulkActionSelected={bulkActionSelected}
                setBulkActionSelected={setBulkActionSelected}
              />
            ) : null}
            {showSyncAction ? (
              <SyncButton
                checkedForms={checkedForms}
                onClick={changeStatusActionProps.onClick}
                dropdownVariant={true}
                bulkActionSelected={bulkActionSelected}
                setBulkActionSelected={setBulkActionSelected}
                statusFilter={statusFilter}
              />
            ) : null}
            {showAssignAction ? (
              <StyledAssignBtn as="div">
                <AssignButton
                  checkedForms={checkedForms}
                  assignedUsers={assignActionProps.assignedUsers}
                  onSelectAssigned={assignActionProps.onSelectAssigned}
                  dropdownVariant={true}
                  bulkActionSelected={bulkActionSelected}
                  setBulkActionSelected={setBulkActionSelected}
                />
              </StyledAssignBtn>
            ) : null}
          </StyledMainDropdown>
        </OverlayTrigger>
      </Nav>
    </AuthGuard>
  );
};

export default DropdownActions;

import { FC } from "react";
import { Route, Routes, Navigate, useSearchParams } from "react-router-dom";

import Forms from "../pages/Forms";
import Form from "../pages/Form";
import Jobs from "../pages/Jobs";
import Settings from "../pages/Settings";
import SyncRequests from "../pages/SyncRequests";
import MIExports from "../pages/MIExports";

interface AppRoutesProps {
  isAdmin?: boolean;
  isBulk?: boolean;
}

// TODO: Add a redirect option to AuthGuard and implement on page level.
const getBulkRoutes = ({ isAdmin, isBulk }: AppRoutesProps) => {
  if (!isAdmin && !isBulk) return null;

  return (
    <>
      <Route
        path="/jobs"
        element={<Jobs />}
      />
      <Route
        path="/syncLog"
        element={<SyncRequests />}
      />
    </>
  );
};

const getAdminRoutes = ({ isAdmin }: AppRoutesProps) => {
  if (!isAdmin) return null;

  return (
    <>
      <Route
        path="/settings"
        element={<Settings />}
      />
    </>
  );
};

const AppRoutes: FC<AppRoutesProps> = ({ isAdmin, isBulk }) => {
  const [searchParams] = useSearchParams();

  return (
    <Routes>
      <Route
        path="/"
        element={<Forms />}
      />
      <Route
        path="/form/:formId/*"
        element={<Form />}
      />
      {getAdminRoutes({ isAdmin })}
      {getBulkRoutes({ isAdmin, isBulk })}
      <Route
        path="/mi/exports"
        element={<MIExports />}
      />
      <Route
        path="/*"
        element={
          <Navigate
            to={`/?${searchParams.toString()}`}
            replace
          />
        }
      />
    </Routes>
  );
};

export default AppRoutes;

import { useState, FC, useCallback } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";

interface DecisionContainerModalBodyProps {
  updateFormDecision: Function;
  decision: string;
  closeModal: Function;
}

const requiresMessage = ["amend"];

export const Body: FC<DecisionContainerModalBodyProps> = ({
  updateFormDecision,
  decision,
  closeModal,
}) => {
  const [validations, setValidations] = useState<Record<string, string>>({});
  const [message, setMessage] = useState("");

  const handleOnChange = useCallback((e: any) => {
    const { value } = e.target;
    setMessage(value);
  }, [setMessage]);

  const handleConfirmation = useCallback(() => {
    if (requiresMessage.includes(decision) && !message) {
      setValidations({
        message: "Message is required.",
      });
      return;
    }
    updateFormDecision(decision, message);
  }, [decision, message, updateFormDecision, setValidations]);

  return (
    <div>
      <Row>
        <Col>
          <Form.Control
            required
            rows={3}
            as="textarea"
            value={message}
            onChange={handleOnChange}
            placeholder="Enter message here."
            isInvalid={Boolean(validations.message && !message)}
          />
          <Form.Control.Feedback type="invalid">
            Required
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-lg-end mt-1">
          <Button
            variant="danger"
            onClick={() => closeModal()}
            style={{ margin: "0 3px 0 0" }}
          >
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleConfirmation}
          >
            Confirm
          </Button>
        </Col>
      </Row>
    </div>
  );
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.printRow = exports.printHeader = void 0;
const headerSize = 12;
const contentSize = 8;
const columnWidth = 40;
const printHeader = ({ x, y, doc, text }) => {
    doc.setFontSize(headerSize);
    doc.text(text, x, y);
    doc.setFontSize(contentSize);
};
exports.printHeader = printHeader;
const getText = (text) => {
    if (!text && typeof text !== "boolean") {
        return "N/A";
    }
    return String(text);
};
const printFieldWithHeader = ({ x, y, doc, text, header, format = getText, }) => {
    (0, exports.printHeader)({
        x,
        y,
        doc,
        text: header,
    });
    doc.text(format(text), x, y + 5);
};
const printRow = ({ startY = 0, x, y, row, doc }) => {
    row.columns.forEach((column, index) => {
        printFieldWithHeader({
            doc,
            x: x + index * 60,
            y: y + startY,
            text: column.value,
            header: column.header,
            format: column.format,
        });
    });
};
exports.printRow = printRow;

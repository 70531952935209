import { FC, useCallback } from "react";
import { useAppToastContext } from "@taxscribe/ui";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import useFormSync from "../../../hooks/useFormSync";
import { NCPTSButtonProps } from "./types";

const NCPTSButton: FC<NCPTSButtonProps> = ({
  refreshAccount,
  ddbKey,
  formType,
}) => {
  const { addToastMessage } = useAppToastContext();
  const [sync, { loading: syncLoading }] = useFormSync({
    formType,
    ddbKey,
  });

  const handleClick = useCallback(async () => {
    if (typeof sync === "function") {
      await sync().catch((e) => {
        addToastMessage({
          type: "error",
          header: "Error",
          body: e.message,
        });
      });
      refreshAccount();
    }
  }, [sync, refreshAccount, addToastMessage]);

  if (syncLoading) return <Spinner animation="border" />;

  return (
    <>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={formType}>Sync to NCPTS</Tooltip>}
      >
        <span>
          <Button
            variant="danger"
            onClick={handleClick}
            className="me-2"
          >
            Sync
          </Button>
        </span>
      </OverlayTrigger>
    </>
  );
};

export default NCPTSButton;

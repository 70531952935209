import { FC } from "react";
import { ListGroup, Row, Col } from "react-bootstrap";

const headers = [
  { str: "ID", length: 1 },
  { str: "Type", length: 2 },
  { str: "Started at", length: 2 },
  { str: "User", length: 2 },
  { str: "Status", length: 1 },
  { str: "Updated at", length: 2 },
  { str: "Data & Result", length: 2 },
];

const TableHeader: FC = () => {
  return (
    <ListGroup.Item>
      <Row className="p-3 listing-row">
        {headers.map((header, i) => {
          return (
            <Col
              key={i}
              xs={header.length}
              className={`d-flex align-items-center flex-column flex-xl-row text-xl-start p-lg-0 fw-bold
                  ${header.str.includes("at") && "text-center"} `}
            >
              {header.str}
            </Col>
          );
        })}
      </Row>
    </ListGroup.Item>
  );
};

export default TableHeader;

import { useLazyQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { useParams } from "react-router-dom";

import { GET_JURISDICTION_FORM_PDF } from "../components/pages/Form/queries";
import { FormPdfQueryResults } from "../components/pages/Form/queries/returnTypes";
import useJurisdictionContext from "./useJurisdictionContext";

export interface UseJurisdictionFormPdfArgs {
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useJurisdictionFormPdf = (opts?: UseJurisdictionFormPdfArgs) => {
  // Leaving this as an option to be changed, but it should be network-only
  // virtually all the time for this request.
  const { fetchPolicy } = opts || { fetchPolicy: "network-only" };
  const { formId } = useParams();
  const {
    jurisdiction: { stateAbbr, name, jurisdictionType },
  } = useJurisdictionContext();
  const variables = {
    stateAbbr,
    jurisdictionType,
    jurisdictionName: name,
    id: Number(formId),
  };

  return useLazyQuery<FormPdfQueryResults>(GET_JURISDICTION_FORM_PDF, {
    variables,
    fetchPolicy,
  });
};

export default useJurisdictionFormPdf;

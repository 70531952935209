import React, {
  FC,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Card, Col, Container, Dropdown, Row } from "react-bootstrap";
import { useFilingYear } from "@taxscribe/ui";
import { useNavigate } from "react-router-dom";

import ExportsTable from "./ExportsTable";
import useJurisdictionContext from "../../../hooks/useJurisdictionContext";
import styled from "styled-components";

interface YearSelectItemProps {
  year: number;
  onClick: (year: number) => void;
}

const YearSelectItem: FC<YearSelectItemProps> = ({ year, onClick }) => {
  const handleClick: MouseEventHandler<HTMLElement> = useCallback(
    (e) => {
      onClick(year);
    },
    [year, onClick],
  );

  return <Dropdown.Item onClick={handleClick}>{year}</Dropdown.Item>;
};

const SelectWrapper = styled.div`
  margin-top: 0.3rem;
  display: flex;
  justify-content: end;
`;

const SelectPrompt = styled.h5`
  padding-left: 1rem;
  padding-top: 0.33rem;
  padding-bottom: 0.33rem;
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
  background: #fff;
  border: 0px;
  margin-top: 0.08rem;
`;

const MIExports: FC = () => {
  const { currentYear } = useFilingYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const { jurisdiction } = useJurisdictionContext();
  const navigate = useNavigate();
  const years = useMemo(() => {
    const startYear = 2020;
    const diff = currentYear - startYear;
    const yearsArray = new Array(diff).fill(currentYear).map((cy, index) => {
      return cy - index;
    });

    return yearsArray;
  }, [currentYear]);

  useEffect(() => {
    if (jurisdiction.stateAbbr !== "MI") {
      navigate("/");
    }
  }, [navigate, jurisdiction]);

  const handleSelect = useCallback(
    (year: number) => {
      setSelectedYear(year);
    },
    [setSelectedYear],
  );

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h1>Download Exports</h1>
          </Col>
          <Col>
            <SelectWrapper>
              <SelectPrompt>Select a year: </SelectPrompt>
              <Dropdown>
                <StyledDropdownToggle
                  variant="light"
                  id="year-dropdown"
                >
                  {selectedYear}
                </StyledDropdownToggle>
                <Dropdown.Menu>
                  {years.map((year) => (
                    <YearSelectItem
                      year={year}
                      key={year}
                      onClick={handleSelect}
                    />
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </SelectWrapper>
          </Col>
        </Row>
      </Container>
      <Card>
        <ExportsTable filingYear={selectedYear} />
      </Card>
    </div>
  );
};

export default MIExports;

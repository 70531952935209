import { FC, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Body: FC = () => (
  <>
    <div className="d-flex py-2">
      <div className="me-3">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="text-warning inline"
          size="xl"
        />
      </div>
      <div>
        <span>Are you sure you want to update the assigned user?</span>
      </div>
    </div>
  </>
);

interface AccountInformationModalFooterProps {
  userId: number;
  formId: number | string | undefined;
  closeModal: Function;
  refreshAccount: Function;
  updateAssignedUser: Function;
}

export const Footer: FC<AccountInformationModalFooterProps> = ({
  userId,
  formId,
  closeModal,
  refreshAccount,
  updateAssignedUser,
}) => {
  const handleUpdate = useCallback(() => {
    updateAssignedUser({
      variables: {
        userId: userId,
        formIds: [Number(formId)],
      },
    })
      .then(closeModal)
      .then(refreshAccount);
  }, [refreshAccount, closeModal, updateAssignedUser, userId, formId]);

  return (
    <Row>
      <Col>
        <Button
          variant="dark"
          className="me-2"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={handleUpdate}
        >
          Confirm
        </Button>
      </Col>
    </Row>
  );
};

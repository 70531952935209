import { FC, ChangeEvent, useCallback } from "react";
import { Form } from "react-bootstrap";

interface CustomSelectorProps {
  label: string;
  selected: number;
  options: Array<any>;
  onChange: Function;
  isDisabled?: boolean;
}

const CustomSelector: FC<CustomSelectorProps> = ({
  label,
  selected,
  options,
  onChange,
  isDisabled = false,
}) => {
  const handleOnChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    const user = Number(value);
    onChange(user);
  }, [onChange]);

  return (
    <Form.Group>
      <Form.Label className="fw-bold">{label}</Form.Label>
      <Form.Select
        aria-label={label}
        value={selected}
        disabled={isDisabled}
        onChange={handleOnChange}
      >
        {options}
      </Form.Select>
    </Form.Group>
  );
};

export default CustomSelector;

import apiConfiguration from "../../config/api";
import bizworksFetch, { BizworksFetchOptions } from "../../hooks/useFetch";

const requestCache: Record<string, Promise<any> | null> = {};

export const requestWithCache = async <ReturnData = any>(
  opts: BizworksFetchOptions,
) => {
  const { method, endpoint } = opts;
  // Only cache GET requests
  if (method !== apiConfiguration.methods.get) {
    return bizworksFetch<ReturnData>(opts);
  }

  if (!requestCache[endpoint]) {
    const promise = bizworksFetch<ReturnData>(opts)
      .finally(() => {
        requestCache[endpoint] = null;
      });
    requestCache[endpoint] = promise;
  }
  return requestCache[endpoint] as Promise<ReturnData>;
};

import { govConfig } from "@taxscribe/react-gov";
import { camelCase } from "lodash";
import {
  firebaseDevConfig,
  firebaseQAConfig,
  firebaseUATConfig,
  firebaseProdConfig,
} from "./firebaseConfig";

interface AppConfig {
  releaseStage: "development" | "qa" | "uat" | "production";
  bppUrl: string;
  ssoUrl: string;
  govApi: string;
  taxbaseApi: string;
  fireSubApi: string;
  firebaseConfig: any;
  authCookieName: string;
}

const hostname = window.location.hostname;
const getCookieName = () => {
  const prefix = "auth";
  const domain = hostname.split(".").slice(1).join("_");
  return camelCase(`${prefix}_${domain}`);
};

const configs: Record<string, AppConfig> = {
  "localhost": {
    ...govConfig.development,
    govApi: "http://localhost:3030",
    firebaseConfig: firebaseDevConfig,
    authCookieName: getCookieName(),
  },
  "qa-bpp.gov.taxscribe.app": {
    ...govConfig.qa,
    govApi: "https://qa-bpp-api.gov.taxscribe.app",
    firebaseConfig: firebaseQAConfig,
    authCookieName: getCookieName(),
  },
  "uat-bpp.gov.taxscribe.app": {
    ...govConfig.uat,
    govApi: "https://uat-bpp-api.gov.taxscribe.app",
    firebaseConfig: firebaseUATConfig,
    authCookieName: getCookieName(),
  },
  "bpp.gov.taxscribe.app": {
    ...govConfig.production,
    govApi: "https://bpp-api.gov.taxscribe.app",
    firebaseConfig: firebaseProdConfig,
    authCookieName: getCookieName(),
  },
};

const getConfig = () => {
  if (hostname.match("localhost")) return configs.localhost;
  const config = configs[hostname];

  if (!config) throw new Error(`No configuration found for ${hostname}`);
  return config;
};

export default getConfig();

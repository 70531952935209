import { FC, useState, useEffect, useCallback, useMemo } from "react";
import { Col, Row, Card, ListGroup, Button } from "react-bootstrap";

import TableHeader from "./TableHeader";
import PageLimiter from "../../PageLimiter";
import { pageLimitChoices, getJobs } from "../../../api/bizworks";
import { DropdownOptions } from "../../../utils/Interfaces";
import useFormNameProcess from "../../../hooks/useProcessFormName";
import { useCurrentOrgMembers } from "@reasongcp/react-fire-sub";
import getUserData from "../../../utils/getUserData";
import { get } from "lodash";
import styled from "styled-components";

interface NewJob {
  id: number;
  jobId: string;
  jurisdictionId: number;
  createdAt: string;
  updatedAt: string;
  queue: string;
  status: string;
  data: Record<string, unknown>;
  results: Record<string, unknown>;
}

const RefreshButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
`;

const jobMapping: Record<string, string> = {
  bulkListingAssignments: "assigned",
  bulkExtensionAssignments: "assigned",
  bulkExtensionApprovals: "approved",
  bulkExtensionSyncings: "synced",
  sendAttachments: "sent",
  emailSending: "sent",
};

const pluralize = (amount: number) => {
  if (amount === 1) {
    return "";
  }

  return "s";
};

const Jobs: FC = () => {
  const formNameProcess = useFormNameProcess();
  const members = useCurrentOrgMembers();

  const [fetchInProgress, setFetchInProgress] = useState(false);
  const [jobs, setJobs] = useState<Array<NewJob>>([]);
  const [pageLimit, setPageLimit] = useState(10);

  const handlePageLimit = useCallback(
    (key: number) => {
      if (pageLimit !== key) {
        setPageLimit(key);
      }
    },
    [pageLimit, setPageLimit],
  );

  const replaceFormType = useCallback(
    (bulkType: string) => {
      const splitName = bulkType.split(" ");
      const formType = splitName[1];
      const convertion = formNameProcess(formType);
      return `${splitName[0]} ${convertion} ${splitName?.[2] || ""}`;
    },
    [formNameProcess],
  );

  const getBulkTypeDescription = useCallback(
    (type: string) => {
      const name = type.includes("sync") ?
        "Bulk Extension Sync" :
        type.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
      return replaceFormType(name);
    },
    [replaceFormType],
  );

  const getFormattedData = useCallback(
    (job: NewJob) => {
      const jobData = job.data as Record<string, any>;
      const totalNumberOfForms = job.data && jobData?.formIds.length;
      const jobDescription = jobMapping[job.queue];
      const successes = get(job, "results.formIds", []).length;

      return {
        bulkType: getBulkTypeDescription(job.queue),
        userName: getUserData(jobData?.user, members).displayName || "N/A",
        jobResults: `${successes} out of ${totalNumberOfForms} form${pluralize(totalNumberOfForms)} successfully ${jobDescription}`,
        ...job,
      };
    },
    [
      getBulkTypeDescription,
      members,
    ],
  );

  const currentPageLimitChoices = useMemo(
    () =>
      pageLimitChoices.map(
        (choice: string): DropdownOptions => ({
          value: choice,
          text: choice.toString(),
          active: pageLimit === Number(choice),
        }),
      ),
    [pageLimit],
  );

  const fetchJobs = useCallback(async () => {
    setFetchInProgress(true);
    const jobsData = await getJobs(pageLimit);
    if (Array.isArray(jobsData) && jobsData.length !== 0) {
      setJobs(jobsData);
    }
    setFetchInProgress(false);
  }, [pageLimit, setFetchInProgress]);

  useEffect(() => {
    fetchJobs();
  }, [fetchJobs]);

  const refreshText = fetchInProgress ? "Refreshing..." : "Refresh";
  return (
    <>
      <RefreshButtonWrapper>
        <Button onClick={fetchJobs}>{refreshText}</Button>
      </RefreshButtonWrapper>
      <Card>
        <ListGroup
          variant="flush"
          className="border-top-0"
        >
          <TableHeader />
          {jobs.length === 0 ? (
            <ListGroup.Item>
              <Row className="p-3 d-flex align-items-center listing-row">
                <Col className="d-flex flex-column flex-xl-row text-center text-xl-center p-lg-0 justify-content-center">
                  There are no background jobs.
                </Col>
              </Row>
            </ListGroup.Item>
          ) : (
            jobs.map((result: NewJob, idx: number) => {
              const {
                id,
                status,
                bulkType,
                createdAt,
                updatedAt,
                userName,
                jobResults,
              } = getFormattedData(result);
              return (
                <ListGroup.Item key={idx}>
                  <Row className="p-3 d-flex align-items-center listing-row">
                    <Col
                      xs={1}
                      className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0"
                    >
                      {id}
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0 text-capitalize"
                    >
                      {bulkType}
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0"
                    >
                      {createdAt}
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0"
                    >
                      {userName}
                    </Col>
                    <Col
                      xs={1}
                      // eslint-disable-next-line max-len
                      className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0 text-capitalize"
                    >
                      {status}
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0"
                    >
                      {updatedAt}
                    </Col>
                    <Col
                      xs={2}
                      className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0"
                    >
                      {jobResults}
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })
          )}
        </ListGroup>
      </Card>
      <PageLimiter
        pageLimit={pageLimit}
        currentPageLimitChoices={currentPageLimitChoices}
        handlePageLimit={handlePageLimit}
      />
    </>
  );
};

export default Jobs;

import getUserData from "./getUserData";
import { AssigneesObj } from "./Interfaces";

const getUsersData = (
  assignees: AssigneesObj[],
  members?: Record<string, any>[],
) => {
  if (!members) return [];

  return assignees.map((assignee) => getUserData(assignee, members));
};

export default getUsersData;

import { FC, ChangeEvent } from "react";
import { Col, Form } from "react-bootstrap";
import { cloneDeep } from "lodash";
import { IMaskInput } from "react-imask";
import { formPeriod } from "../../../../utils/Interfaces";

interface FormPeriodInputProps {
  state: {
    formPeriod?: formPeriod;
    setter?: Function;
  };
}

const DateMask: FC = (props) => (
  <IMaskInput
    mask="00/00/0000"
    {...props}
  />
);

const FormPeriodInput: FC<FormPeriodInputProps> = ({ state }) => {
  const { formPeriod, setter } = state;

  if (!formPeriod || !setter) return null;

  const handleDeadline = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    const newRecord = cloneDeep(formPeriod);
    if (newRecord) {
      newRecord.filingDeadline = value;
      setter(newRecord);
    }
  };

  return (
    <Col md={4}>
      <Form.Label htmlFor={formPeriod.formType}>
        {formPeriod.formType}
      </Form.Label>
      <Form.Control
        as={DateMask}
        id={formPeriod.formType}
        type="text"
        value={formPeriod.filingDeadline}
        onChange={handleDeadline}
      />
    </Col>
  );
};

export default FormPeriodInput;

import React, { FC } from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";

const HeaderWrapper = styled.div`
  border-bottom: 1px solid #dee2e6;
`;

const FormsTableHeaders: FC = () => {
  return (
    <HeaderWrapper>
      <Row className="p-3 d-flex justify-content-between align-items-center listing-row">
        <Col md={5} xl={6}>
          <Row>
            <Col xl={1}/>
            <Col xl={3}>
              Account Number
            </Col>
            <Col xl={4}>
              Business Name
            </Col>
            <Col xl={4}>
              Address
            </Col>
          </Row>
        </Col>
        <Col
          md={7}
          xl={6}
        >
          <Row>
            <Col lg={3} className="text-center">
              Assignee
            </Col>
            <Col lg={1} className="text-center">
              Year
            </Col>
            <Col lg={4} className="text-center">
              Status
            </Col>
            <Col lg={2} className="text-center">
              Last Updated
            </Col>
            <Col lg={1} className="text-center">
              Messages
            </Col>
            <Col lg={1} className="text-center">
              View
            </Col>
          </Row>
        </Col>
      </Row>
    </HeaderWrapper>
  );
};

export default FormsTableHeaders;

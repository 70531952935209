import { FC } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ErrorPlaceholderProps {
  msg: string;
  redirect: string;
}
const ErrorPlaceholder: FC<ErrorPlaceholderProps> = ({ msg, redirect }) => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (redirect.includes("http")) {
      window.location.href = redirect;
    } else {
      navigate(redirect);
    }
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center mt-5">
          <h3>{msg}</h3>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            type="button"
            variant="info"
            onClick={handleOnClick}
          >
            Return
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default ErrorPlaceholder;

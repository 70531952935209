import { useMutation, gql, DocumentNode } from "@apollo/client";

// It is expected that any sync mutation will utilize a variable named ddbKey.
const SYNC_IPP = gql`
  mutation SyncNCPTSData($ddbKey: String!) {
    syncNCPTSData(ddbKey: $ddbKey)
  }
`;

const syncQueries: Record<string, DocumentNode> = {
  "IPP Listing": SYNC_IPP,
  "Listing": SYNC_IPP,
};

interface UseFormSyncOpts {
  formType: string;
  ddbKey: string;
}

const useFormSync = ({ formType, ddbKey }: UseFormSyncOpts) => {
  const query = syncQueries[formType];
  return useMutation(query, {
    variables: { ddbKey },
  });
};

export default useFormSync;

const SyncErrorHandler = (statusCode: number, message: string) => {
  // -1: notified but error from their server
  // -2: notified but no result from their server
  // -3: our attempt but error on our side
  // Solution: Might require retry later, or check message attached
  if (statusCode === -1 || statusCode === -2 || statusCode === -3) {
    // eslint-disable-next-line max-len
    return `Sync failed. Open up a sync service ticket with Farragut. ${message}`;
  }

  // -4: accountPrimaryNumber cannot be converted to int (10 digits)
  // Solution: might require checking modification (on our side) on the
  // account_primary_number
  if (statusCode === -4) {
    // eslint-disable-next-line max-len
    return `Sync failed. Try updating the account number to the standard format. ${message}`;
  }

  // -5: form not found
  // Solution: No possible solution based on old docs
  if (statusCode === -5) {
    return `Sync failed. Abstract not found in NCPTS. ${message}`;
  }

  return null;
};

export default SyncErrorHandler;

import { FC } from "react";
import { Col, Row, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import { formatDate } from "../../../utils";

const StyledLink = styled(Link)`
  text-decoration: none;
  font-weight: bold;
  color: black;
`;

interface SyncRequestsKeys {
  id: number;
  formId: number;
  documentUuid: string;
  accountNumberPrimary: string;
  statusCode: string;
  message: string;
  createdat: string;
}

interface TableBodyProps {
  data: Array<SyncRequestsKeys>;
}

const TableBody: FC<TableBodyProps> = ({ data }) => {
  if (!data.length) {
    return (
      <ListGroup.Item>
        <Row className="p-3 d-flex align-items-center listing-row">
          <Col className="d-flex flex-column flex-xl-row text-center text-xl-center p-lg-0 justify-content-center">
            There are no sync requests.
          </Col>
        </Row>
      </ListGroup.Item>
    );
  }

  return (
    <>
      {data.map((result: SyncRequestsKeys, idx: number) => {
        const {
          id,
          formId,
          accountNumberPrimary,
          statusCode,
          message,
          createdat,
        } = result;
        return (
          <ListGroup.Item key={idx}>
            <Row className="p-3 d-flex align-items-center listing-row">
              <Col
                xs={1}
                className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0"
              >
                {id}
              </Col>
              <Col
                xs={2}
                // eslint-disable-next-line max-len
                className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0 text-capitalize"
              >
                <StyledLink
                  to={`/form/${formId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Document Reference{" "}
                  <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    aria-hidden="true"
                  />
                </StyledLink>
              </Col>
              <Col
                xs={2}
                className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0"
              >
                {accountNumberPrimary}
              </Col>
              <Col
                xs={2}
                className="d-flex align-items-center flex-column flex-xl-row text-center p-lg-0"
              >
                {statusCode}
              </Col>
              <Col
                xs={3}
                // eslint-disable-next-line max-len
                className="d-flex align-items-center flex-column flex-xl-row text-center text-xl-start p-lg-0 text-capitalize"
              >
                {message}
              </Col>
              <Col
                xs={2}
                className="d-flex flex-column flex-xl-row text-center text-xl-start p-lg-0"
              >
                {formatDate(createdat)}
              </Col>
            </Row>
          </ListGroup.Item>
        );
      })}
    </>
  );
};

export default TableBody;

import { FC } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { DropdownButton, ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Account } from "../utils/Interfaces";
import useAssignments, {
  GovAssignmentWithUser,
  TransitionWithUser,
} from "../hooks/useAssignments";

const HistoryBtnStyled = styled(DropdownButton)`
  .dropdown-toggle::after {
    content: none;
  }
  .btn svg {
    background: #000;
    padding: 7px 10px;
    border-radius: 5px;
  }
  button {
    border: none;
  }
  .btn:hover,
  .btn:focus-visible {
    background-color: transparent !important;
  }
  .btn:hover > svg,
  .btn:focus-visible > svg {
    background-color: #424649 !important;
  }
  & .dropdown-menu {
    margin-top: 1rem;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  }
`;

const HistoryIconContainer = styled.div`
  width: 22rem;
`;

const StyledListGroup = styled(ListGroup)`
  max-height: 75vh;
  overflow: scroll;
`;

interface RecordDescriptionProps {
  record: TransitionWithUser | GovAssignmentWithUser;
}

const RecordDescription: FC<RecordDescriptionProps> = ({ record }) => {
  if (record.__typename === "GovAssignment") {
    if (record.userId === 0) {
      return (
        <>
          Form was unassigned at{" "}
          <b>{new Date(Number(record.createdAt)).toLocaleString()}</b>
        </>
      );
    }

    return (
      <>
        Form was reassigned to <b>{record.user?.contactName}</b> at{" "}
        <b>{new Date(Number(record.createdAt)).toLocaleString()}</b>
      </>
    );
  }

  if (record.__typename === "SubmissionTransition") {
    if (record.user) {
      return (
        <>
          <b>{record.user.contactName}</b> changed the status of the form to{" "}
          <b>{record.toStatus}</b> at{" "}
          <b>{new Date(Number(record.createdAt)).toLocaleString()}</b>
        </>
      );
    }
    return (
      <>
        The form was marked as <b>{record.toStatus}</b> at{" "}
        <b>{new Date(Number(record.createdAt)).toLocaleString()}</b>
      </>
    );
  }

  return null;
};

interface HistoryContainerProps {
  account: Account;
}

const HistoryContainer: FC<HistoryContainerProps> = ({ account }) => {
  const { formId } = useParams();
  const { changes } = useAssignments(Number(formId));

  console.log(changes);
  return (
    <>
      <HistoryBtnStyled
        autoClose="outside"
        align={{ lg: "end" }}
        id="dropdown-history-button"
        variant="tranparent"
        className="py-0"
        title={
          <FontAwesomeIcon
            icon={faInfo}
            size="sm"
            className="text-white"
          />
        }
      >
        <HistoryIconContainer id="history-container">
          <StyledListGroup
            variant="flush"
            className="rounded"
          >
            <ListGroup.Item>
              <>
                Submitted on:{" "}
                <b>{new Date(account.submittedAt).toLocaleDateString()}</b>
              </>
            </ListGroup.Item>
            {changes.map((record) => {
              return (
                <ListGroup.Item key={record.id}>
                  <RecordDescription record={record} />
                </ListGroup.Item>
              );
            })}
          </StyledListGroup>
        </HistoryIconContainer>
      </HistoryBtnStyled>
    </>
  );
};

export default HistoryContainer;

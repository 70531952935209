import { AssigneesObj } from "./Interfaces";

/*
 * Unfortunately, a bunch of user data is stored in several places, and
 * collecting it from said places is a bit of a pain. This function marries the
 * data and returns it in the form that we want.
 */
const getUserData = (
  assignee: AssigneesObj,
  members?: Record<string, any>[],
) => {
  if (!members) return { ...assignee, displayName: "" };

  const member = members?.find((m) => {
    const legacyUuidMatch =
      m.firesub_uuid && m.firesub_uuid === assignee?.firesub_uuid;
    const uuidMatch = m.uuid && m.uuid === assignee?.uuid;
    return legacyUuidMatch || uuidMatch;
  });
  return {
    ...assignee,
    displayName: member?.displayName || "",
  };
};

export default getUserData;

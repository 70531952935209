"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const manufacturedHomesEntries = [
    [
        { key: "parcelId", label: "Parcel ID" },
        { key: "year", label: "Year" },
        { key: "make", label: "Make " },
    ],
    [
        { key: "model", label: "Model " },
        { key: "body", label: "Body" },
        { key: "width", label: "Width" },
    ],
    [
        { key: "length", label: "Length" },
        { key: "yearAcquired", label: "Year Acquired" },
        { key: "cost", label: "Acquisition Cost" },
    ],
    [
        { key: "vin", label: "VIN" },
        { key: "serialNumber", label: "Serial Number" },
        { key: "plateNumber", label: "Plate Number" },
    ],
    [
        { key: "manufacturedHomePark", label: "Manufacturedured Home Park" },
        { key: "lotNumber", label: "Lot Number" },
        { key: "titleNumber", label: "Title Number" },
    ],
    [
        { key: "occupant", label: "Occupant" },
        { key: "titleNameOne", label: "Title Name One" },
        { key: "titleNameTwo", label: "Title Name Two" },
    ],
    [
        { key: "movingPermitNumber", label: "Moving Permit Number" },
        { key: "movingPermitIssueDate", label: "Moving Permit Issue Date" },
        { key: "buildingPermitNumber", label: "Building Permit Number" },
    ],
    [{ key: "buildingPermitIssueDate", label: "Building Permit Issue Date" }],
];
exports.default = manufacturedHomesEntries;

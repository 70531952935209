import { useQuery, WatchQueryFetchPolicy } from "@apollo/client";
import { useParams } from "react-router-dom";

import GET_JURISDICTION_FORM from "../components/pages/Form/queries";
import { FormQueryResults } from "../components/pages/Form/queries/returnTypes";
import useJurisdictionContext from "./useJurisdictionContext";

export interface UseJurisdictionFormArgs {
  fetchPolicy?: WatchQueryFetchPolicy;
}

const useJurisdictionForm = (opts?: UseJurisdictionFormArgs) => {
  const { fetchPolicy } = opts || {};
  const { formId } = useParams();
  const {
    jurisdiction: { stateAbbr, name, jurisdictionType },
  } = useJurisdictionContext();
  const variables = {
    stateAbbr,
    jurisdictionType,
    jurisdictionName: name,
    id: Number(formId),
  };

  return useQuery<FormQueryResults>(GET_JURISDICTION_FORM, {
    variables,
    fetchPolicy,
  });
};

export default useJurisdictionForm;

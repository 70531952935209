"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ownershipEntries = [
    [
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "middleName", label: "Middle Name" },
    ],
    [
        { key: "suffix", label: "Suffix" },
        { key: "additionalName", label: "Additional" },
        { key: "accountNumber", label: "Account Number" },
    ],
    [
        { key: "addressOne", label: "Address One" },
        { key: "addressTwo", label: "Address Two" },
    ],
    [
        { key: "city", label: "City" },
        { key: "state", label: "State" },
        { key: "zipCode", label: "Zip" },
    ],
    [
        { key: "email", label: "Email" },
        { key: "phoneNumber", label: "Phone Number" },
    ],
];
exports.default = ownershipEntries;

import { FC, useState, useEffect, useCallback } from "react";
import { Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import { useAppToastContext } from "@taxscribe/ui";

import PeriodsHandler from "./PeriodsHandler";
import {
  getFormPeriods,
  updateFormFilingDeadline,
} from "../../../../api/bizworks";
import useJurisdictionContext from "../../../../hooks/useJurisdictionContext";

const PageContainer = styled.div`
  margin: 30px;
`;

interface PeriodDeadlinesProps {
  show: boolean;
}

const PeriodDeadlines: FC<PeriodDeadlinesProps> = ({ show }) => {
  const {
    jurisdiction: { stateAbbr },
  } = useJurisdictionContext();
  const { addToastMessage } = useAppToastContext();
  const currentYear = new Date().getFullYear();
  const [formPeriods, setFormPeriods] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await getFormPeriods(currentYear);
      setFormPeriods(response);
    })();
  }, [currentYear]);

  const handleUpdateData = useCallback(async () => {
    try {
      const response = await updateFormFilingDeadline(formPeriods);
      if (response.ok) {
        addToastMessage({
          type: "success",
          header: "Updated",
          body: "The form deadlines have been updated successfully",
        });
      } else {
        addToastMessage({
          type: "danger",
          header: "Failed",
          body: response.message,
        });
      }
    } catch (err) {
      addToastMessage({
        type: "danger",
        header: "Failed",
        body: "The attempt to update the data has failed",
      });
    }
  }, [addToastMessage, formPeriods]);

  if (!show || stateAbbr !== "NC") return null;

  if (!formPeriods.length) return null;

  return (
    <PageContainer>
      <Row className="mb-2">
        <Col>
          <h2>Manage Form Period Deadlines</h2>
          <p className="text-muted">
            Set the business personal property listing extended deadline for
            online listings.
          </p>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col>
          <h4 className="mb-3">Year: {currentYear}</h4>
          <Row>
            <PeriodsHandler
              formPeriods={formPeriods}
              setFormPeriods={setFormPeriods}
            />
          </Row>
          <Row className="mt-2">
            <Col className="d-flex justify-content-end">
              <Button
                variant="success"
                onClick={handleUpdateData}
              >
                Save
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default PeriodDeadlines;

import { FC } from "react";
import { gql } from "@apollo/client";
import { SSOAuthBoundary } from "@reasongcp/react-fire-sub";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";

import config from "../../config";
import LoadingPlaceholder from "./LoadingPlaceholder";
import { useApolloClientWithAuth } from "@taxscribe/apollo-helpers";

const NoMatch: FC = () => {
  const [searchparams] = useSearchParams();
  const handleRedirect = () => {
    window.location.replace(`${config.ssoUrl}?${searchparams.toString()}`);
  };

  return (
    <>
      <Row>
        <Col className="d-flex justify-content-center mt-5">
          <h3>Please login to TaxScribe Government SSO</h3>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex justify-content-center">
          <Button
            variant="success"
            onClick={() => handleRedirect()}
            className="justify-content-lg-end"
          >
            Go to Log In
          </Button>
        </Col>
      </Row>
    </>
  );
};

const NoAuthRoutes: FC = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={<NoMatch />}
      />
    </Routes>
  );
};

interface AuthBoundaryProps {
  children: any;
}

const AuthPlaceholder = () => <LoadingPlaceholder placeholderLocation="AuthBoundary" />;
const AuthBoundary: FC<AuthBoundaryProps> = ({ children }) => {
  const client = useApolloClientWithAuth(config.fireSubApi);

  return (
    <SSOAuthBoundary
      gql={gql}
      client={client}
      InvalidPlaceHolder={NoAuthRoutes}
      LoadingPlaceHolder={AuthPlaceholder}
    >
      {children}
    </SSOAuthBoundary>
  );
};

export default AuthBoundary;

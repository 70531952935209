import { gql, OperationVariables, QueryResult, useLazyQuery } from "@apollo/client";
import { useAppToastContext } from "@taxscribe/ui";
import { useCallback } from "react";

import useJurisdictionContext from "./useJurisdictionContext";

const GET_EXPORT_URL = gql`
  query GetExportUrl($jurisdictionId: Int!, $name: String!) {
    jurisdiction(id: $jurisdictionId) {
      id
      taxFormExport(name: $name) {
        url
        name
        provider
      }
    }
  }
`;

interface JurisdictionExports {
  name: string;
  provider: string;
  url: string;
}

interface GetExportUrlResults {
  jurisdiction: {
    id: number;
    taxFormExport: JurisdictionExports;
  } | null;
}

const useDownloadTaxFormExport = (name: string) => {
  const { jurisdiction } = useJurisdictionContext();
  const { addToastMessage } = useAppToastContext();
  const [getExport, results] = useLazyQuery<GetExportUrlResults>(
    GET_EXPORT_URL,
    {
      fetchPolicy: "network-only",
      variables: { name, jurisdictionId: jurisdiction.id },
    },
  );

  const downloadExport = useCallback(() => {
    getExport()
      .then((result) => {
        const taxFormExport = result.data?.jurisdiction?.taxFormExport;
        if (!taxFormExport) {
          throw new Error("No export found");
        }

        window.open(taxFormExport.url, "_blank");
      })
      .catch((err) => {
        console.error(err);
        addToastMessage({
          body: "Error downloading file",
          type: "error",
          header: "Error",
        });
      });
  }, [addToastMessage, getExport]);

  return [downloadExport, results] as [
    () => void,
    QueryResult<GetExportUrlResults, OperationVariables>,
  ];
};

export default useDownloadTaxFormExport;

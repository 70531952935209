import { useState, useMemo, useEffect } from "react";
import {
  initialState,
  JurisdictionContext,
} from "../../utils/JurisdictionContext";
import { getUserJurisdiction } from "../../api/bizworks";
import LoadingPlaceholder from "./LoadingPlaceholder";

const JurContextPlaceholder = () => <LoadingPlaceholder placeholderLocation="JurisdictionContextProvider" />;
const JurisdictionContextProvider = ({ children }: { children: any }) => {
  // TODO: Review how the current jurisdiction is set.
  const [jurisdiction, setJurisdiction] = useState(initialState);
  const jurisdictionProvider = useMemo(
    () => ({ jurisdiction, setJurisdiction }),
    [jurisdiction, setJurisdiction],
  );

  useEffect(() => {
    (async () => {
      const current = await getUserJurisdiction();
      setJurisdiction(current);
    })();
  }, []);

  if (!jurisdiction.id) {
    return <JurContextPlaceholder />;
  }

  return (
    <JurisdictionContext.Provider value={jurisdictionProvider}>
      {children}
    </JurisdictionContext.Provider>
  );
};

export default JurisdictionContextProvider;

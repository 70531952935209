import { gql, useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import useJurisdictionContext from "./useJurisdictionContext";

const GET_EXISTING_EXPORTS = gql`
  query GetJursdictionExports(
    $jurisdictionId: Int,
    $filingYear: Int,
  ) {
    jurisdiction(id: $jurisdictionId) {
      id
      taxFormExports(filingYear: $filingYear) {
        url
        name
        provider
      }
    }
  }
`;

export interface JurisdictionExport {
  name: string;
  provider: string;
}

export interface GetExistingExportsResults {
  jurisdiction: {
    id: number;
    taxFormExports: JurisdictionExport[]
  } | null;
}

const useLookupExports = (filingYear?: number) => {
  const { jurisdiction } = useJurisdictionContext();
  const { id } = jurisdiction;
  const [getExports, results] = useLazyQuery<GetExistingExportsResults>(
    GET_EXISTING_EXPORTS,
    { variables: { filingYear, jurisdictionId: id } },
  );

  useEffect(() => {
    if (id) getExports();
  }, [getExports, id]);

  return results;
};

export default useLookupExports;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const physicalLocationEntries = [
    [
        { key: "addressOne", label: "Physical Location" },
        { key: "addressTwo", label: "Physical Location Two" },
    ],
    [
        { key: "city", label: "City" },
        { key: "state", label: "State" },
        { key: "zipCode", label: "Zip" },
    ],
];
exports.default = physicalLocationEntries;

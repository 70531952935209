import React, { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormDataUnion } from "@taxscribe/gql-queries";
import { Metadata, MIExemption, MIExemptionData } from "@taxscribe/form";

import { CurrentSubmission } from "../queries/returnTypes";

interface MIExemptionViewProps {
  formState: FormDataUnion;
  metadata: Partial<CurrentSubmission> & Metadata;
}

const MIExemptionView: FC<MIExemptionViewProps> = ({ metadata, formState }) => {
  const data = formState as MIExemptionData;
  return (
    <MIExemption.Provider
      readOnly
      metadata={metadata}
      initialState={data}
      onSubmit={() => Promise.resolve()}
    >
      <Container fluid>
        <Row>
          <Col sm={12}>
            <MIExemption.Instructions />
            <MIExemption.TaxpayerInfo />
            <MIExemption.ExemptionAttachment />
            <MIExemption.Signature />
          </Col>
        </Row>
      </Container>
    </MIExemption.Provider>
  );
};

export default MIExemptionView;

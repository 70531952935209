import React, { FC, useCallback, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import exportBSA from "../../api/bizworks/exportBSA";
import useJurisdictionForm from "../../hooks/useJurisdictionForm";

const StyledSpinner = styled(Spinner)`
  max-height: 12px;
  max-width: 12px;
`;

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const miStatementMatch = new RegExp(/mi_listings$/);
const BSAExportButton: FC = () => {
  const { formId } = useParams();
  const { data } = useJurisdictionForm();
  const [fetching, setFetching] = useState(false);

  const exportTheStuff = useCallback(async () => {
    setFetching(true);
    const response = await exportBSA(Number(formId));
    setFetching(false);

    if (response.data) window.open(response.data.url, "_blank");
  }, [formId]);

  const ddbTable = data?.jurisdiction?.form?.ddbTable || "";
  const isMiStatement = miStatementMatch.test(ddbTable);

  if (!isMiStatement) return null;
  if (fetching) {
    return (
      <StyledButton>
        <StyledSpinner animation="border" />
      </StyledButton>
    );
  }

  return <StyledButton onClick={exportTheStuff}>Export BS&A CSV</StyledButton>;
};

export default BSAExportButton;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const watercraftLength = [
    { key: "feet", label: "Length (Feet)" },
    { key: "inches", label: "Length (Inches)" },
];
const formatInches = (value) => {
    var _a;
    return ((_a = value.inches) === null || _a === void 0 ? void 0 : _a.toString()) || "N/A";
};
const formatFeet = (value) => {
    var _a;
    return (((_a = value.feet) === null || _a === void 0 ? void 0 : _a.toString()) || "N/A");
};
const watercraftEntries = [
    [
        { key: "year", label: "Year" },
        { key: "make", label: "Make" },
        { key: "model", label: "Model" },
    ],
    [
        { key: "length", label: "Length (Feet)", format: formatFeet },
        { key: "length", label: "Length (Inches)", format: formatInches },
        { key: "registrationNumber", label: "Registration Number" },
    ],
    [
        { key: "fuelCode", label: "Fuel Code" },
        { key: "modelCode", label: "Model Code" },
        { key: "propulsionCode", label: "Propulsion Code" },
    ],
    [
        { key: "primaryUseCode", label: "Primary Use Code" },
        { key: "yearAcquired", label: "Year Acquired" },
        { key: "cost", label: "Acquisition Cost" },
    ],
    [
        { key: "motorMakeOne", label: "Motor Make One" },
        { key: "motorHpOne", label: "Motor HP One" },
        { key: "motorSerialNumberOne", label: "Motor Serial Number One" },
    ],
    [
        { key: "motorMakeTwo", label: "Motor Make Two" },
        { key: "motorHpTwo", label: "Motor HP Two" },
        { key: "motorSerialNumberTwo", label: "Motor Serial Number Two" },
    ],
    [
        { key: "marina", label: "Marina" },
        { key: "slipNumber", label: "Slip Number" },
        { key: "slipNumberExtension", label: "Slip Number Extension" },
    ],
];
exports.default = watercraftEntries;

import { FC, useCallback } from "react";
import { Badge } from "react-bootstrap";
import { DropdownOptions } from "../utils/Interfaces";
import styled from "styled-components";

const FilterBadgeStyled = styled(Badge)`
  background-color: rgb(128, 0, 196, 0.1) !important;
  border: 1px solid #800060;
  color: #800060;
  font-size: 0.8rem;
`;

interface FilterBadgeComponent {
  filter: string;
  value: string | number | null;
  formFilterItems: Array<DropdownOptions>;
  assignedToFilterItems: Array<DropdownOptions>;
  syncedFilterItems: Array<DropdownOptions>;
}

const FilterBadge: FC<FilterBadgeComponent> = ({
  filter,
  value,
  formFilterItems,
  assignedToFilterItems,
  syncedFilterItems,
}) => {
  const getFilterValue = useCallback(
    (filter: string, value: string | number | null) => {
      if (filter === "Form Type") {
        const filterValue = formFilterItems.find(
          (selected: DropdownOptions) => selected.value === Number(value),
        );
        if (filterValue && filterValue.text) return filterValue.text;
      }
      if (filter === "Assigned To") {
        const filterValue = assignedToFilterItems.find(
          (selected: DropdownOptions) => selected.value === value,
        );
        if (filterValue && filterValue.text) return filterValue.text;
      }
      if (filter === "Synced") {
        const filterValue = syncedFilterItems.find(
          (selected: DropdownOptions) => selected.value === value,
        );
        if (filterValue && filterValue.text) return filterValue.text;
      }
      return value;
    },
    [assignedToFilterItems, formFilterItems, syncedFilterItems],
  );

  const showBadge = value || filter === "Assigned To";

  return showBadge ? (
    <FilterBadgeStyled
      pill
      bg="light"
      className="m-1 p-2"
    >
      {`${filter}: ${getFilterValue(filter, value)}`}
    </FilterBadgeStyled>
  ) : null;
};

export default FilterBadge;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const helpers_1 = require("./helpers");
const printSection = ({ doc, startY, segment, sectionHeader, segmentEntries, }) => {
    if (sectionHeader) {
        doc.setFontSize(20);
        doc.text(sectionHeader, 105, startY, { align: "center" });
        doc.setFontSize(10);
    }
    segmentEntries.forEach((entries, chunkIndex) => {
        const columns = entries.map(({ key, label, format }) => {
            return {
                format,
                header: label,
                value: segment[key],
            };
        });
        (0, helpers_1.printRow)({
            doc,
            startY,
            x: 20,
            y: 12 * (chunkIndex + 1),
            row: {
                columns,
                columnWidth: 15,
            },
        });
    });
};
exports.default = printSection;

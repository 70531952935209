import { gql, useMutation } from "@apollo/client";
import { GET_SUBMISSION_ASSIGNMENTS } from "./useAssignments";
import useJurisdictionContext from "./useJurisdictionContext";

const UPDATE_ASSIGNEE = gql`
  mutation AssignForms($userId: Int!, $formIds: [Int]!, $jurisdictionId: Int!) {
    assignForms(
      userId: $userId
      formIds: $formIds
      jurisdictionId: $jurisdictionId
    )
  }
`;

export interface UpdateAssigneeResults {
  assignForms: number[];
}

interface Variables {
  userId?: number;
  formIds?: number[];
  jurisdictionId?: number | null;
}

const useUpdateAssignee = () => {
  const {
    jurisdiction: { id },
  } = useJurisdictionContext();

  return useMutation<UpdateAssigneeResults, Variables>(UPDATE_ASSIGNEE, {
    refetchQueries: [GET_SUBMISSION_ASSIGNMENTS],
    variables: {
      jurisdictionId: id,
    },
  });
};

export default useUpdateAssignee;

import { FC, useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";
import CustomDropdown from "../../CustomDropdown";
import { pageLimitChoices } from "../../../api/bizworks";
import { DropdownOptions } from "../../../utils/Interfaces";
import styled from "styled-components";
import { PaginationSelector } from "@taxscribe/ui";

const StyledCustomDropdown = styled(CustomDropdown)`
  .dropdown-item.active,
  .dropdown-item:active {
    background: none;
    color: #212529 !important;
    font-weight: 700 !important;
  }
`;

const PaginationWrapper = styled.div`
  ul {
    margin-bottom: 0px;
  }
  .active> .page-link {
    background-color: #d2f0f2;
  };
`;

interface FormsTableFooterProps {
  page: number;
  count: number;
  limit: number;
  setLimit: Function;
  selectPage: (page: number) => void;
}

const FormsTableFooter: FC<FormsTableFooterProps> = ({
  page,
  count,
  selectPage,
  limit,
  setLimit,
}) => {
  const handleAll = (limitValue: string) =>
    limitValue === "15000" ? "All" : limitValue;

  const currentPageChoicesFormatted = useMemo(
    () =>
      pageLimitChoices.map(
        (choice: string): DropdownOptions => ({
          value: choice,
          text: handleAll(choice),
          active: Number(limit) === Number(choice),
        }),
      ),
    [limit],
  );

  const scrollToTop = useCallback(
    () => window.scrollTo({ top: 0, behavior: "smooth" }),
    [],
  );

  const onSelectPageChoices = useCallback((key: number) => {
    if (limit !== key) {
      setLimit(key);
      scrollToTop();
    }
  }, [limit, scrollToTop, setLimit]);

  return (
    <Row className="mt-3 p-0">
      <Col xs={6}>
        <PaginationWrapper>
          <PaginationSelector
            current={page}
            hideOnSinglePage
            select={selectPage}
            perPage={limit}
            range={count / limit}
          />
        </PaginationWrapper>
      </Col>
      <Col className="d-flex align-items-end justify-content-end">
        <h2 className="fs-6 me-2">Page Limit</h2>
        <StyledCustomDropdown
          id="currentPageLimit"
          text={handleAll(limit.toString())}
          variant="outline-dark"
          items={currentPageChoicesFormatted}
          onSelect={onSelectPageChoices}
        />
      </Col>
    </Row>
  );
};

export default FormsTableFooter;

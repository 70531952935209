import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";

import useJurisdictionContext from "./useJurisdictionContext";
import { AssigneesWithDisplayName } from "../utils/Interfaces";
import useUserListContext from "./useUserListContext";

export interface GovAssignment {
  id: number;
  status: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
  previousUserId: number;
  __typename: "GovAssignment";
}

export interface GovAssignmentWithUser extends GovAssignment {
  user?: AssigneesWithDisplayName ;
}

export interface Transition {
  id: number;
  userId: number;
  toStatus: string;
  createdAt: string;
  updatedAt: string;
  __typename: "SubmissionTransition";
}

export interface TransitionWithUser extends Transition {
  user?: AssigneesWithDisplayName ;
}

export interface GetSubmissionAssignmentsResult {
  jurisdiction: {
    form: {
      id: number;
      currentSubmission: {
        id: number;
        govAssignments: GovAssignment[];
        transitions: Transition[];
      };
    } | null;
  } | null;
}

export const GET_SUBMISSION_ASSIGNMENTS = gql`
  query GetSubmissionAssignments(
    $formId: Int!
    $stateAbbr: String!
    $jurisdictionName: String!
    $jurisdictionType: String!
  ) {
    jurisdiction(
      stateAbbr: $stateAbbr
      jurisdictionName: $jurisdictionName
      jurisdictionType: $jurisdictionType
    ) {
      form(id: $formId) {
        id
        currentSubmission {
          id
          govAssignments {
            id
            status
            userId
            createdAt
            updatedAt
            previousUserId
          }
          transitions {
            id
            userId
            toStatus
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

const useAssignments = (formId: number) => {
  const { userList } = useUserListContext();
  const {
    jurisdiction: { stateAbbr, name, jurisdictionType },
  } = useJurisdictionContext();
  const { data, ...rest } = useQuery<GetSubmissionAssignmentsResult>(
    GET_SUBMISSION_ASSIGNMENTS,
    {
      variables: {
        stateAbbr,
        jurisdictionType,
        jurisdictionName: name,
        formId: formId,
      },
    },
  );

  const currentSubmission = data?.jurisdiction?.form?.currentSubmission || null;

  const submissionEvents = useMemo(() => {
    const transitions = currentSubmission?.transitions || [];
    const assignments = currentSubmission?.govAssignments || [];
    // "changes" is the above two cobmined and sorted by created_at.
    const changes = [...assignments, ...transitions].sort(
      (recordA, recordB) => {
        if (recordA.createdAt > recordB.createdAt) return -1;
        if (recordA.createdAt < recordB.createdAt) return 1;
        return 0;
      },
    ).map((record) => {
      const user = userList.find((u) => u.loginId === record.userId);
      return {
        ...record,
        user,
      };
    });

    return {
      changes,
      transitions,
      assignments,
    };
  }, [userList, currentSubmission]);

  return {
    data,
    ...submissionEvents,
    ...rest,
  };
};

export default useAssignments;

import { FC } from "react";
import { Row, Col } from "react-bootstrap";

const NoResults: FC = () => (
  <Row className="text-center my-4">
    <Col>
      <h2 className="ls-1 text-uppercase">No Records Found</h2>
    </Col>
  </Row>
);

export default NoResults;

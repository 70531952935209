import { FC } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useAppToastContext } from "@taxscribe/ui";

import { Account } from "../../../utils/Interfaces";
import {
  bulkExtensionApprovals,
  bulkExtensionSyncings,
} from "../../../api/bizworks";
import useFormNameProcess from "../../../hooks/useProcessFormName";
import useUpdateAssignee from "../../../hooks/useUpdateAssignee";

interface BodyProps {
  formType: string;
  checkedForms: Array<Account>;
  setCheckedForms: Function;
  bulkStatus: string;
  bulkUser: number;
  assignees: Array<any>;
  closeModal: Function;
  showConfirmationModal: Function;
  bulkActionSelected: string | null;
  setBulkActionSelected: Function;
  addToastMessage: ReturnType<typeof useAppToastContext>["addToastMessage"];
}

interface GetComponentDataArgs extends BodyProps {
  updateAssignee: ReturnType<typeof useUpdateAssignee>[0];
}

const getComponentData = ({
  formType,
  checkedForms,
  bulkStatus,
  bulkUser,
  assignees,
  closeModal,
  updateAssignee,
  addToastMessage,
  setCheckedForms,
  showConfirmationModal,
  bulkActionSelected,
  setBulkActionSelected,
}: GetComponentDataArgs) => {
  const toastFailure = () =>
    addToastMessage({
      type: "danger",
      header: "Error",
      body: "An error was encountered reassigning the selected forms",
    });

  const toastSuccess = () =>
    addToastMessage({
      type: "success",
      header: "Forms Reassigned",
      body: "The selected forms have been reassigned",
    });

  const handleBulkAssignment = async () => {
    try {
      const formIds = checkedForms.map((form: Account) => form.formId);
      const response = await updateAssignee({
        variables: {
          formIds,
          userId: bulkUser,
        },
      });
      if (response?.data?.assignForms) {
        toastSuccess();
        closeModal();
      }
    } catch (error) {
      toastFailure();
    }
    setCheckedForms([]);
    setBulkActionSelected(null);
  };

  const handleBulkStatus = async () => {
    const formIds = checkedForms.map((form: Account) => form.formId);
    try {
      if (bulkStatus === "Approve") {
        const approveResponse = await bulkExtensionApprovals(formIds);
        if (approveResponse && approveResponse.ok && approveResponse.job) {
          const { id } = approveResponse.job;
          closeModal();
          showConfirmationModal(id);
        }
      }
      if (bulkStatus === "Sync") {
        const syncResponse = await bulkExtensionSyncings(formIds);
        if (syncResponse && syncResponse.ok && syncResponse.job) {
          const { id } = syncResponse.job;
          closeModal();
          showConfirmationModal(id);
        }
      }
    } catch (err) {
      toastFailure();
    }
    setCheckedForms([]);
    setBulkActionSelected(null);
  };

  const getDataByBulkAction = () => {
    const changeStatusAction = {
      optionSelectedStructure: () => (
        <>
          <span className="fw-bold">Status selected:</span> {bulkStatus}
        </>
      ),
      informativeMsg:
        "*The extension(s) in the table above will be updated with the status selected",
      confirmFunction: handleBulkStatus,
    };

    if (bulkActionSelected === "approve" || bulkActionSelected === "sync") {
      return changeStatusAction;
    }

    const assignAction = {
      optionSelectedStructure: () => (
        <>
          <span className="fw-bold">User selected:</span>{" "}
          {bulkUser &&
            assignees.find((assignee) => Number(assignee.loginId) === bulkUser)
              .displayName}
        </>
      ),
      informativeMsg:
        "*The form(s) in the table above will be updated with the user selected",
      confirmFunction: handleBulkAssignment,
    };

    if (bulkActionSelected === "assign") {
      return assignAction;
    }

    return {
      optionSelectedStructure: () => <></>,
      informativeMsg: "No bulk action available",
      confirmFunction: () => {},
    };
  };

  return getDataByBulkAction();
};

export const Body: FC<BodyProps> = (props) => {
  const formNameProcess = useFormNameProcess();
  const [updateAssignee] = useUpdateAssignee();

  const { addToastMessage } = useAppToastContext();
  const { checkedForms, formType } = props;
  const { informativeMsg, optionSelectedStructure } = getComponentData({
    ...props,
    updateAssignee,
    addToastMessage,
  });
  const formName = formNameProcess(formType);

  return (
    <>
      <Row>
        <Col>{optionSelectedStructure()}</Col>
      </Row>
      <Row>
        <Col>
          <br />
          <span className="fw-bold">
            <>{formName} selected:</>
          </span>
          <Table
            striped
            bordered
            hover
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Account Number</th>
                <th>Business Name</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {checkedForms.map((form: Account, index: number) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{form.accountNumberPrimary || "N/A"}</td>
                  <td>{form.businessName}</td>
                  <td>{form.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <p className="text-muted">{informativeMsg}</p>
        </Col>
      </Row>
    </>
  );
};

export const Footer: FC<BodyProps> = (props) => {
  const { closeModal } = props;
  const [updateAssignee] = useUpdateAssignee();
  const { confirmFunction } = getComponentData({
    ...props,
    updateAssignee,
  });

  return (
    <Row>
      <Col className="d-flex justify-content-lg-end mt-1">
        <Button
          variant="danger"
          onClick={() => closeModal()}
          style={{ margin: "0 3px 0 0" }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => confirmFunction()}
        >
          Confirm
        </Button>
      </Col>
    </Row>
  );
};

import { FC, SyntheticEvent, ReactNode } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { DropdownProps } from "react-bootstrap/Dropdown";
import { DropdownOptions } from "../utils/Interfaces";

interface DropdownComponent {
  items: DropdownOptions[];
  id: string;
  variant?: string;
  text: ReactNode;
  className?: string;
  onSelect?: any;
  dropDirection?: DropdownProps["drop"];
  disabled?: boolean;
  size?: "lg" | "sm";
}

const CustomDropdown: FC<DropdownComponent> = ({
  text,
  variant,
  id,
  items,
  className,
  onSelect,
  dropDirection,
  disabled = false,
  size,
}) => {
  return (
    <DropdownButton
      id={id}
      title={text}
      variant={variant}
      onSelect={(eventKey: string | null, e: SyntheticEvent<unknown>) =>
        onSelect(eventKey, e)
      }
      className={className}
      drop={dropDirection}
      disabled={disabled}
      size={size}
      as="div"
    >
      <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
        {items.map((item: DropdownOptions, index) => (
          <Dropdown.Item
            key={index}
            eventKey={item.value}
            active={item.active}
          >
            {item.text}
          </Dropdown.Item>
        ))}
      </div>
    </DropdownButton>
  );
};

export default CustomDropdown;

import { useMemo } from "react";
import { setContext } from "@apollo/client/link/context";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";

export const formatDate = (date: string) =>
  new Date(date).toLocaleString("en-US", {
    dateStyle: "short",
    timeStyle: "short",
  });

export const trimString = (text: string) => text.replace(/^\s+|\s+$/gm, "");

// Array to CSV
const downloadCSV = (csv: string, fileName: string) => {
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  link.click();
};

const arrayToCsv = (data: Record<string, any>[]) => {
  return data
    .map((row) =>
      Object.values(row)
        .map(String)
        .map((v: string) => v.replace(/"/g, "\"\""))
        .map((v: string) =>
          v === "null" || v === "undefined" ? "\"-\"" : `"${v}"`,
        )
        .join(","),
    )
    .join("\r\n");
};

export const exportToCSV = (
  fileName: string,
  headers: Array<any>,
  data: Array<any>,
) => {
  const csv = arrayToCsv([headers, ...data]);
  downloadCSV(csv, fileName);
};

/*
 * Initializes the client via a memoized hook so that we can use the current
 * user to inject Firebase auth credentials that are fresh into GQL requests.
 */
export const useApolloClient = (uri: string, user: any) => {
  return useMemo(() => {
    const link = createHttpLink({
      uri: uri + "graphql",
    });

    const authLink = setContext(async (_, { headers }) => {
      const jwt = (await user?.getIdToken()) || "";
      return {
        headers: {
          ...headers,
          authorization: jwt,
        },
      };
    });

    return new ApolloClient({
      link: authLink.concat(link),
      cache: new InMemoryCache(),
    });
  }, [uri, user]);
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const formatDate = (value) => {
    return new Date(Number(value)).toLocaleDateString();
};
const affirmationEntries = [
    [
        { key: "firstName", label: "First Name" },
        { key: "lastName", label: "Last Name" },
        { key: "phoneNumber", label: "Phone Number" },
    ],
    [
        { key: "email", label: "Email" },
        { key: "signature", label: "Signature" },
        { key: "submittedAt", label: "Date", format: formatDate },
    ],
];
exports.default = affirmationEntries;

import { FC, useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { getAccountDetails } from "../../../../api/bizworks";

interface ExtensionStatusProps {
  extensionId: number | null | undefined;
}

const ExtensionStatus: FC<ExtensionStatusProps> = ({ extensionId }) => {
  const [extensionStatus, setExtensionStatus] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    if (extensionId) {
      (async () => {
        const extensionDetails = await getAccountDetails(Number(extensionId));
        if (extensionDetails?.status) {
          setExtensionStatus(extensionDetails?.status);
        }
      })();
    } else {
      setExtensionStatus("Not Applicable");
    }
  }, [extensionId]);

  return (
    <Row>
      <Col
        md={6}
        lg={3}
      >
        <Form.Group>
          <Form.Label htmlFor="extensionStatus">Extension Status</Form.Label>
          <Form.Control
            disabled
            type="text"
            id="extensionStatus"
            placeholder="Extension Status"
            defaultValue={extensionStatus}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ExtensionStatus;

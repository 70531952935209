import { TXExtension } from "@taxscribe/form";

import createSinglePageFormView from "./createSinglePageFormView";

const TXExtensionView = createSinglePageFormView({
  Provider: TXExtension.Provider,
  FormComponent: TXExtension.FormPage,
});

export default TXExtensionView;

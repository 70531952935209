import config from "../config";
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const getBearerToken = async () => {
  const app = getApp();
  const auth = getAuth(app);
  const token = await auth?.currentUser?.getIdToken();
  return `Bearer ${token}`;
};

const apiConfiguration = {
  baseUrl: config.govApi,
  version: "v2",
  methods: {
    get: "GET",
    post: "POST",
    patch: "PATCH",
    put: "PUT",
  },
  getAuthToken: getBearerToken,
};

export default apiConfiguration;

import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { EnvBanner } from "@taxscribe/ui";
import Container from "react-bootstrap/Container";
import { GovSidebar, SidebarMenuItem, TSGIcon } from "@taxscribe/react-gov";

import config from "../../config";
import AppRoutes from "./AppRoutes";
import AuthGuard from "./RolesGuard";
import useUserContext from "../../hooks/useUserContext";
import useJurisdictionContext from "../../hooks/useJurisdictionContext";
import StateGuard from "./StateGuard";

const ContentWrapper = styled.main`
  @media (min-width: 992px) {
    width: calc(100% - 280px);
    margin-left: auto;
  }
`;

const appUrls = {
  BPP: config.bppUrl,
  SSO: config.ssoUrl,
};

const AppLayout: FC = () => {
  const { user } = useUserContext();
  const { jurisdiction } = useJurisdictionContext();

  const userBulk = user?.roles.includes("bulk");
  const userAdmin = user?.roles.includes("admin");

  return (
    <div>
      <GovSidebar
        currentApp="BPP"
        appUrls={appUrls}
        jurisdiction={jurisdiction}
        appDescription={`GOV BPP ${jurisdiction.name} ${jurisdiction.stateAbbr}`}
      >
        <SidebarMenuItem
          topLevel
          link="/"
          as={Link}
        >
          <TSGIcon /> Tax Forms - Dashboard
        </SidebarMenuItem>
        <AuthGuard roles={["admin", "bulk"]}>
          <SidebarMenuItem
            as={Link}
            link="/jobs"
          >
            Jobs
          </SidebarMenuItem>
        </AuthGuard>
        {jurisdiction.stateAbbr === "NC" ? (
          <AuthGuard roles={["admin", "bulk"]}>
            <SidebarMenuItem
              as={Link}
              link="/syncLog"
            >
              Sync Log
            </SidebarMenuItem>
          </AuthGuard>
        ) : null}
        <AuthGuard roles={["admin"]}>
          <SidebarMenuItem
            as={Link}
            link="/settings"
          >
            Settings
          </SidebarMenuItem>
        </AuthGuard>
        <StateGuard states={["MI"]}>
          <SidebarMenuItem
            as={Link}
            link="/mi/exports"
          >
            Exports
          </SidebarMenuItem>
        </StateGuard>
      </GovSidebar>
      <ContentWrapper className="d-flex flex-column">
        <EnvBanner releaseStage={config.releaseStage} />
        <Container
          fluid
          id="main-content"
          className="py-3"
        >
          <AppRoutes
            isAdmin={userAdmin}
            isBulk={userBulk}
          />
        </Container>
      </ContentWrapper>
    </div>
  );
};

export default AppLayout;

import {
  FormSearchFilters,
  FormSearchSort,
  formPeriod,
} from "../../utils/Interfaces";
import baseApiConfigs from "../../config/api";
import { requestWithCache } from "./helpers";

const {
  methods: { get, patch, post, put },
} = baseApiConfigs;

export const getNcptsDebugData = async (accountId: number) => {
  const endpoint = `accounts/${accountId}/ncpts`;
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

// Get current user values
export const getCurrentUser = async () => {
  const endpoint = "users/me";
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

// Get users for assignTo filter
export const getAssignees = async () => {
  const endpoint = "users";
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

export const pageLimitChoices = ["10", "100", "500", "1000", "15000"];

// Get data for BPP listings
const createFormsQuery = (selectedFilters: FormSearchFilters) => {
  const businessNameFirstLetter =
    selectedFilters.search && selectedFilters.search.length !== 1 ?
      null :
      selectedFilters.search;
  const query = {
    taxYear: selectedFilters.taxYear, // 2018
    assignedToId: selectedFilters.assignedTo, // 0
    status: selectedFilters.status,
    formTypeId: selectedFilters.form, // 2
    syncStatus: selectedFilters.synced, // add option to
    accountNumber: selectedFilters.search,
    businessName: selectedFilters.search,
    businessAddress: selectedFilters.search,
    businessNameFirstLetter,
    pageLimitInfo: {
      current: selectedFilters.currentPageLimit,
      choices: pageLimitChoices,
    },
    page: "1",
    pageLimit: selectedFilters.currentPageLimit.toString(),
  };
  return {
    encoded: encodeURIComponent(JSON.stringify(query)),
    decoded: query,
  };
};

const createFormsSort = (selectedSort: FormSearchSort) => {
  const query = {
    businessName: selectedSort.businessName,
    accountNumber: selectedSort.accountNumber,
    currentAssignee: selectedSort.assignee,
    status: selectedSort.status,
    statusTimestamp: selectedSort.statusLastChangedAt,
  };
  return encodeURIComponent(JSON.stringify(query));
};

interface GetFormsParams {
  filters: FormSearchFilters,
  sort: FormSearchSort,
  page: number,
}

// Get listings all with params
export const getForms = async ({
  filters,
  sort,
  page,
}: GetFormsParams) => {
  const endpoint = "accounts";

  const { encoded: jsonQuery, decoded } = createFormsQuery(filters);
  const jsonSort = createFormsSort(sort);
  const queryParameters = `q=${jsonQuery}&s=${jsonSort}`;

  const headers = {
    "X-Page-Current": page,
    "X-Page-Limit":
      !decoded.pageLimit || decoded.pageLimit < "0" ?
        "1000" :
        decoded.pageLimit,
  };

  const { data, headers: responseHeaders } = await requestWithCache({
    endpoint,
    method: get,
    headers,
    queryParameters,
  });
  const { formsData, formsOnQueue } = data;
  const total = (responseHeaders && Number(responseHeaders.get("X-Page-Count"))) || 0;

  return {
    total,
    formsData,
    formsOnQueue,
  };
};

// Form details - Messages container
export const getFormMessages = async (formId: number) => {
  const endpoint = `messages/${formId}`;
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

// Get account details information
export const getAccountDetails = async (accountId: number) => {
  const endpoint = `accounts/${accountId}`;
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

// Send message in account details
export const sendFormMessage = async (formId: number, message: string) => {
  const endpoint = `messages/${formId}`;
  const body = JSON.stringify({ message });
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };
  const { data } = await requestWithCache({
    endpoint,
    method: post,
    headers,
    body,
  });
  return data;
};

export const readFormMessages = async (formId: number, messages: number[]) => {
  const endpoint = `messages/${formId}/read`;
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };

  const { data } = await requestWithCache({
    endpoint,
    headers,
    body: JSON.stringify(messages),
    method: "PATCH",
  });

  return data;
};

// Update account user
export const updateFormAssignedUser = async (
  submissionId: number,
  userId: number,
) => {
  const endpoint = `assignments/${submissionId}/reassign/${userId}`;
  const { data } = await requestWithCache({ endpoint, method: put });
  return data;
};

// Update account number
export const updateAccountNumber = async (
  formId: number,
  accountNumber: string,
) => {
  const endpoint = `accounts/${formId}/accountNumber`;
  const body = JSON.stringify({ accountNumber });
  const headers = { "Content-Type": "application/json; charset=utf-8" };
  const { data } = await requestWithCache({
    endpoint,
    method: patch,
    headers,
    body,
  });
  return data;
};

export const setFormDecision = async (
  formId: number,
  decision: string,
  message: string,
) => {
  const endpoint = `accounts/${formId}/${decision}`;
  const body = JSON.stringify({ message });
  const headers = { "Content-Type": "application/json; charset=utf-8" };

  const { data } = await requestWithCache({
    endpoint,
    method: patch,
    headers,
    body: decision !== "accept" && decision !== "sync" ? body : null,
  });
  return data;
};

// Get PDF from form
export const getAccountPDF = async (formId: number) => {
  const endpoint = `accounts/${formId}/details`;
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

// Get history from form
export const getHistory = async (formId: number) => {
  const endpoint = `accounts/${formId}/history`;
  const { data } = await requestWithCache({ endpoint, method: get });
  if (!Array.isArray(data)) {
    return [];
  }
  return data;
};

// Get jobs
export const getJobs = async (rowLimit: number) => {
  const endpoint = "jobs";

  const headers = {
    "X-Page-Current": 0,
    "X-Page-Limit": rowLimit,
  };

  const { data } = await requestWithCache({
    endpoint,
    method: get,
    headers,
  });
  return data;
};

// Assign user to listing in bulk
export const bulkListingAssignments = async (
  targetUserId: number,
  formIds: Array<Number>,
) => {
  const endpoint = "accounts/bulkListingAssignments";
  const body = JSON.stringify({ targetUserId, formIds });
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };
  const { data } = await requestWithCache({
    endpoint,
    method: post,
    headers,
    body,
  });
  return data;
};

// Assign user to extension in bulk
export const bulkExtensionAssignments = async (
  targetUserId: number,
  formIds: Array<Number>,
) => {
  const endpoint = "accounts/bulkExtensionAssignments";
  const body = JSON.stringify({ targetUserId, formIds });
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };
  const { data } = await requestWithCache({
    endpoint,
    method: post,
    headers,
    body,
  });
  return data;
};

// Approve extensions on bulk
export const bulkExtensionApprovals = async (formIds: Array<Number>) => {
  const endpoint = "accounts/bulkExtensionApprovals";
  const body = JSON.stringify(formIds);
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };
  const { data } = await requestWithCache({
    endpoint,
    method: post,
    headers,
    body,
  });
  return data;
};

// Sync extensions on bulk
export const bulkExtensionSyncings = async (formIds: Array<Number>) => {
  const endpoint = "accounts/bulkExtensionSyncings";
  const body = JSON.stringify(formIds);
  const headers = {
    "Content-Type": "application/json; charset=utf-8",
  };
  const { data } = await requestWithCache({
    endpoint,
    method: post,
    headers,
    body,
  });
  return data;
};

// Queque job for sending attachments to user
export const sendAttachments = async (formId: number) => {
  const endpoint = `accounts/${formId}/sendAttachments`;
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

// Get User jurisdiction
export const getUserJurisdiction = async () => {
  const endpoint = "jurisdictions/current";
  const { data } = await requestWithCache({ endpoint, method: get });
  return data;
};

// Update user status(active/inactive) to show on userList
export const updateUserActive = async (userId: number, active: boolean) => {
  const endpoint = `users/${userId}/active/${active}`;
  const { data } = await requestWithCache({ endpoint, method: put });
  return data;
};

// Get Form Periods
export const getFormPeriods = async (year: number) => {
  const endpoint = "form/periods";
  const queryParameters = `year=${year}`;

  const { data } = await requestWithCache({
    endpoint,
    method: get,
    queryParameters,
  });

  return data;
};

// Update form filing deadlines
export const updateFormFilingDeadline = async (
  formPeriods: Array<formPeriod>,
) => {
  const endpoint = "form/period/deadlines";
  const body = JSON.stringify({ formPeriods });
  const headers = { "Content-Type": "application/json; charset=utf-8" };
  const { data } = await requestWithCache({
    endpoint,
    method: patch,
    headers,
    body,
  });
  return data;
};

// Get sync requests responses
export const getSyncRequests = async (pageLimit: number) => {
  const endpoint = "accounts/sync/requests";

  const headers = {
    "X-Page-Current": pageLimit,
    "X-Page-Limit": !pageLimit || pageLimit < 0 ? 10 : pageLimit,
  };

  const { data } = await requestWithCache({
    endpoint,
    method: get,
    headers,
  });

  return data;
};

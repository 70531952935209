import { FC } from "react";
import { FormProvider, NCListing } from "@taxscribe/form";

import ExtensionStatus from "../CustomFields/ExtensionStatus";
import createFlatFormView, {
  StandardFlatFormViewProps,
} from "./createFlatFormView";

const ExtraContent: FC<Pick<StandardFlatFormViewProps, "extensionId">> = ({
  extensionId,
}) => (
  <div>
    <h1>Extension Status</h1>
    <ExtensionStatus extensionId={extensionId} />
    <hr />
  </div>
);

const NCListingView = createFlatFormView({
  ExtraContent,
  includeNcptsDebug: true,
  Provider: NCListing.Provider as FormProvider,
  navLinks: NCListing.navLinks,
});

export default NCListingView;

import { FC, ReactNode } from "react";
import NoResults from "./NoResults";
import LoadingPlaceholder from "../../shared/LoadingPlaceholder";

interface ResultsProps {
  children: ReactNode;
  isLoading: boolean;
  results: Array<any>;
}

const ResultsPlaceholder = () => <LoadingPlaceholder placeholderLocation="Results" />;
const Results: FC<ResultsProps> = ({ children, isLoading, results }) => {
  if (isLoading) {
    return <ResultsPlaceholder />;
  }

  if (!results.length) {
    return <NoResults />;
  }

  return <>{children}</>;
};

export default Results;

import { FC, useCallback, useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";

import UserActive from "./UserActive";
import PeriodDeadlines from "./PeriodDeadlines";
import useJurisdictionContext from "../../../hooks/useJurisdictionContext";

const Settings: FC = () => {
  const {
    jurisdiction: { stateAbbr },
  } = useJurisdictionContext();
  const [showPage, setShowPage] = useState<string>("UserActive");

  const onClick = useCallback((page: string) => {
    setShowPage(page);
  }, [setShowPage]);

  return (
    <Container fluid>
      <Row>
        <Col
          lg={2}
          md={3}
          sm={4}
          className="px-0"
        >
          <Nav
            id="userbar"
            className="flex-column py-3 border-md-end"
          >
            <div className="flex-column mb-3 pe-2">
              <span className="text-uppercase text-muted ls-1 px-3">
                <small>Settings</small>
              </span>
            </div>
            <Nav.Link
              onClick={() => onClick("UserActive")}
              className="bg-light text-secondary"
            >
              <small>User Active</small>
            </Nav.Link>
            {stateAbbr === "NC" ? (
              <Nav.Link
                onClick={() => onClick("PeriodDeadlines")}
                className="bg-light text-secondary"
              >
                <small>Form Period Deadlines</small>
              </Nav.Link>
            ) : null}
          </Nav>
        </Col>
        <Col
          lg={10}
          md={9}
          sm={8}
        >
          <UserActive show={showPage === "UserActive"} />
          {stateAbbr === "NC" ? (
            <PeriodDeadlines show={showPage === "PeriodDeadlines"} />
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default Settings;

import { FC, ReactNode } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

interface DecisionButtonProps {
  id: string;
  tooltip: string | ReactNode;
  onClick: Function;
  buttonName: string;
  buttonVariant: string;
  disabled?: boolean;
  hidden?: boolean;
}

const DecisionButton: FC<DecisionButtonProps> = ({
  id,
  hidden,
  tooltip,
  onClick,
  buttonName,
  buttonVariant,
  disabled = false,
}) => {
  if (hidden) return null;

  return (
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
    >
      <span>
        <Button
          variant={buttonVariant}
          onClick={() => onClick("review")}
          className="me-2"
          disabled={disabled}
        >
          {buttonName}
        </Button>
      </span>
    </OverlayTrigger>
  );
};

export default DecisionButton;

import { noop } from "lodash";
import { FC, useCallback } from "react";
import { Nav, Button, Dropdown } from "react-bootstrap";

interface SyncButtonProps {
  onClick: Function;
  checkedForms: Array<any>;
  dropdownVariant?: boolean;
  bulkActionSelected?: string | null;
  setBulkActionSelected?: Function;
  statusFilter: string | null;
}

const SyncButton: FC<SyncButtonProps> = ({
  onClick,
  checkedForms,
  dropdownVariant = false,
  bulkActionSelected = "",
  setBulkActionSelected = noop,
  statusFilter,
}) => {
  const isSelected = bulkActionSelected === "sync";
  const setAction = useCallback(() => {
    if (isSelected) {
      setBulkActionSelected(null);
    } else {
      setBulkActionSelected("sync");
    }
  }, [isSelected, setBulkActionSelected]);

  if (dropdownVariant) {
    return (
      <Dropdown.Item
        onClick={() => setAction()}
        active={isSelected}
        disabled={statusFilter !== "Approved"}
      >
        Sync
      </Dropdown.Item>
    );
  }

  return (
    <Nav className="my-2 my-lg-0 ms-lg-3">
      <Button
        variant="danger"
        type="button"
        onClick={() => onClick("Sync")}
        disabled={checkedForms.length === 0}
      >
        Sync
      </Button>
    </Nav>
  );
};

export default SyncButton;

import { FC } from "react";
import { Row, Col, Button } from "react-bootstrap";

export const Body: FC = () => (
  <>
    You should receive an email shortly with a link to download all attachments
  </>
);

interface AttachmentsContainerModalFooterProps {
  closeModal: () => void;
}

export const Footer: FC<AttachmentsContainerModalFooterProps> = ({
  closeModal,
}) => {
  return (
    <Row>
      <Col>
        <Button
          variant="success"
          onClick={closeModal}
          className="justify-content-lg-end"
        >
          Confirm
        </Button>
      </Col>
    </Row>
  );
};

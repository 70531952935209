import { useState, useEffect, FC, useCallback, useMemo } from "react";
import { Col, Row } from "react-bootstrap";

import Results from "./Results";
import CustomNavbar from "../../CustomNavbar";
import FormsTable from "./FormsTable";
import FormsTableFooter from "./FormsTableFooter";
import FilterContainer from "../../FilterContainer";

import useFormNameProcess from "../../../hooks/useProcessFormName";
import useUserListContext from "../../../hooks/useUserListContext";
import useJurisdictionFormTypes from "../../../hooks/useJurisdictionFormTypes";
import { getForms } from "../../../api/bizworks";
import { trimString, formatDate, exportToCSV } from "../../../utils";
import { validateCheckmark } from "../../BulkActions/validations";
import { Account, AccountFilter, formWithJobRelated } from "../../../utils/Interfaces";
import { useHydration } from "@taxscribe/ui";
import { camelCase } from "lodash";
import TotalCount from "./TotalCount";
import useStateDefaultFormType from "../../../hooks/useStateDefaultFormType";

const currentYear = new Date().getFullYear();
const useStateAndReset = (
  initialState: any,
  setPage: (page: number) => void,
) => {
  const [state, setState] = useState(initialState);
  const setAndResetPage = useCallback(
    (val: any) => {
      setPage(1);
      setState(val);
    },
    [setPage, setState],
  );
  return [state, setAndResetPage];
};

const useSetFilterValue = <K extends keyof AccountFilter, >(
  key: K,
  accountFilters: AccountFilter,
  setAccountFilters: (f: AccountFilter) => void,
) => {
  return useCallback((value: AccountFilter[K]) => {
    setAccountFilters({
      ...accountFilters,
      [key]: value,
    });
  }, [key, accountFilters, setAccountFilters]);
};

// TODO: Refactor this entire component to be less like spaghetti...
const Forms: FC = () => {
  const defaultFormType = useStateDefaultFormType();
  const defaultFilters = useMemo(() => ({
    status: "Delivered",
    assignedTo: 0,
    taxYear: currentYear,
    form: defaultFormType?.id || 0,
    synced: "",
    search: "",
    currentPageLimit: 100,
    sort: { text: "", value: "", active: true },
  }), [defaultFormType]);

  const { userList } = useUserListContext();
  const { formTypes } = useJurisdictionFormTypes();
  const formNameProcess = useFormNameProcess();

  const { persist, data: filters } = useHydration("formSearchFilters");
  const [accountFilters, setAccountFilters] = useState<AccountFilter>({
    ...defaultFilters,
    ...(filters || {}),
    form: filters?.form || defaultFormType?.id || 0,
  });

  const setStatus = useSetFilterValue(
    "status",
    accountFilters,
    setAccountFilters,
  );

  const setAssignedTo = useSetFilterValue(
    "assignedTo",
    accountFilters,
    setAccountFilters,
  );

  const setTaxYear = useSetFilterValue(
    "taxYear",
    accountFilters,
    setAccountFilters,
  );

  const setForm = useSetFilterValue(
    "form",
    accountFilters,
    setAccountFilters,
  );

  const setSynced = useSetFilterValue(
    "synced",
    accountFilters,
    setAccountFilters,
  );

  const setSearch = useSetFilterValue(
    "search",
    accountFilters,
    setAccountFilters,
  );

  const [page, setPage] = useState(1);
  const [sortCriteria, setSortCriteria] = useStateAndReset(
    accountFilters.sort,
    setPage,
  );

  const [searchValue, setSearchValue] = useState(accountFilters.search);
  const [results, setResults] = useState<Array<Account>>([]);
  const [count, setCount] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [currentPageLimit, setCurrentPageLimit] = useState(100);
  const [checkedForms, setCheckedForms] = useState<Array<Account>>([]);
  const [formsWaitingUpdate, setFormsWaitingUpdate] = useState<
    Array<formWithJobRelated>
  >([]);
  const [bulkActionSelected, setBulkActionSelected] = useState(null);

  const handleSortType = useCallback(
    (aStatus: string, bStatus: string) => {
      setPage(1);
      const correctOrder = [
        "Approved",
        "Delivered",
        "Inadmissible",
        "In Review",
        "Returned",
      ];
      if (sortCriteria.value === "ASC") {
        return correctOrder.indexOf(aStatus) - correctOrder.indexOf(bStatus);
      }
      return correctOrder.indexOf(bStatus) - correctOrder.indexOf(aStatus);
    },
    [setPage, sortCriteria.value],
  );

  const handleStatusSort = useCallback(
    (data: Account[]) => {
      setPage(1);
      return data.sort((a: Account, b: Account) =>
        handleSortType(a["status"], b["status"]),
      );
    },
    [handleSortType, setPage],
  );

  const handleSearch = useCallback(
    () => {
      setSearch(searchValue);
      setPage(1);
    },
    [setSearch, setPage, searchValue],
  );

  const handleDataExport = useCallback(() => {
    const headers = [
      "Account Number",
      "Business Name",
      "Business Address",
      "Business City",
      "Jurisdiction",
      "Assigned To",
      "Tax Year",
      "Status",
      "Status Changed At",
      "Submitted At",
    ];
    const data = results.map((result: Account) => ({
      accountNumber: result.accountNumberPrimary,
      businessName: result.businessName,
      businessAddress: result.businessAddress,
      businessCity: result.businessCity,
      jurisdictionName: result.jurisdictionName,
      assignedToName: result.assignedToName,
      taxYear: result.taxYear,
      status: result.status,
      statusChangedAt: formatDate(result.statusChangedAt),
      submittedAt: formatDate(result.submittedAt),
    }));
    exportToCSV("Export.csv", headers, data);
  }, [results]);

  const selectAll = useCallback(() => {
    const prevResults = [...results];
    const allForms = prevResults.filter((result: any) => {
      const {
        formId,
        formType,
        status,
        accountNumberPrimary,
        assignedToName,
        syncStatus,
      } = result;
      const { isDisabled } = validateCheckmark({
        formId,
        formType,
        status,
        formsWaitingUpdate,
        bulkActionSelected,
        accountNum: accountNumberPrimary ?
          trimString(accountNumberPrimary) :
          "",
        assignedToName: assignedToName ? trimString(assignedToName) : "",
        syncStatus,
        formNameProcess,
      });
      return !isDisabled;
    });
    if (allForms.length === checkedForms.length) {
      setCheckedForms([]);
    } else {
      setCheckedForms(allForms);
    }
  }, [
    bulkActionSelected,
    checkedForms.length,
    formNameProcess,
    formsWaitingUpdate,
    results,
  ]);

  const clearFilters = useCallback(() => {
    setAccountFilters(defaultFilters);
    setSortCriteria(defaultFilters.sort);
    setSearchValue("");
    setPage(1);
  }, [
    setPage,
    setSearchValue,
    defaultFilters,
    setSortCriteria,
  ]);

  useEffect(() => {
    persist({
      ...accountFilters,
      currentPageLimit,
    });
  }, [
    persist,
    accountFilters,
    currentPageLimit,
  ]);

  useEffect(() => {
    setLoading(true);
    const sortKey = camelCase(sortCriteria.text || "");
    const sort = {
      [sortKey]: sortCriteria?.value,
    };

    // Debounce this so it doesn't DDOS the server
    const activeSearch = window.setTimeout(async () => {
      getForms({ filters: accountFilters, sort, page })
        .then(({ formsData, formsOnQueue, total }) => {
          setCount(total);
          setResults(
            sortCriteria.text === "Status" ?
              handleStatusSort(formsData) :
              formsData,
          );
          setFormsWaitingUpdate(formsOnQueue);
          setCheckedForms([]);
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }, 300);

    return () => {
      window.clearTimeout(activeSearch);
    };
  }, [
    accountFilters,
    page,
    sortCriteria,
    currentPageLimit,
    handleStatusSort,
    bulkActionSelected,
  ]);

  return (
    <>
      <CustomNavbar
        assignees={userList}
        handleSearch={handleSearch}
        handleExport={handleDataExport}
        formType={accountFilters.form}
        checkedForms={checkedForms}
        setCheckedForms={setCheckedForms}
        formTypes={formTypes}
        bulkActionSelected={bulkActionSelected}
        setBulkActionSelected={setBulkActionSelected}
        statusFilter={accountFilters.status}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      <FilterContainer
        accountFilter={accountFilters}
        assignees={userList}
        setStatusFilter={setStatus}
        setAssignedToFilter={setAssignedTo}
        setTaxYearFilter={setTaxYear}
        setFormFilter={setForm}
        setSyncedFilter={setSynced}
        sortFilter={sortCriteria}
        setSortFilter={setSortCriteria}
        selectAll={selectAll}
        results={results}
        clearFilters={clearFilters}
        formTypes={formTypes}
      />
      <Row>
        <Col>
          <Results
            results={results}
            isLoading={isLoading}
          >
            <>
              <TotalCount
                limit={currentPageLimit}
                count={count}
              />
              <FormsTable
                results={results}
                checkedForms={checkedForms}
                setCheckedForms={setCheckedForms}
                formsWaitingUpdate={formsWaitingUpdate}
                bulkActionSelected={bulkActionSelected}
              />
              <FormsTableFooter
                page={page}
                count={count}
                selectPage={setPage}
                limit={currentPageLimit}
                setLimit={setCurrentPageLimit}
              />
            </>
          </Results>
        </Col>
      </Row>
    </>
  );
};

export default Forms;

import { FC, PropsWithChildren, ReactNode, useCallback } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { useAppModalContext, useAppToastContext } from "@taxscribe/ui";

import { Body } from "../Modal/DecisionContainer";
import DecisionButtonsContainer from "./DecisionButtonsContainer";
import { setFormDecision, updateFormAssignedUser } from "../../api/bizworks";
import { Account, AssigneesObj } from "../../utils/Interfaces";
import SyncErrorHandler from "../../utils/syncErrorHandler";
import useUserListContext from "../../hooks/useUserListContext";
import usePdfRegen from "../../hooks/usePdfRegen";
import { useParams } from "react-router-dom";
import useJurisdictionForm from "../../hooks/useJurisdictionForm";
import AuthGuard from "../shared/RolesGuard";
import BSAExportButton from "./BSAExportButton";
import NCIPPPrintButton from "../shared/NCIPPPrintButton";
import useJurisdictionFormPdf from "../../hooks/useJurisdictionFormPdf";

interface DecisionContainerProps {
  account: Account;
  refreshAccount: () => void;
  currentUser: AssigneesObj;
}

const StyledButton = styled(Button)`
  margin-right: 0.5rem;
`;

const WithDebug: FC<PropsWithChildren> = ({ children }) => {
  if (localStorage.getItem("DEBUG") === "TRUE") {
    return <>{children}</>;
  }

  return null;
};

// Leaving this as I will have to revisit at a later date.
const Debugger: FC = () => {
  // const members = useCurrentOrgMembers();
  // const userDoc = useUserDoc();
  // const org = useCurrentOrganization();
  // const member = useCurrentOrgMember();
  // console.log("members", members);
  // console.log("member", member);
  // console.log("userDoc", userDoc);
  // console.log("org", org);
  return null;
};

const PdfRegenButton: FC = () => {
  const { formId } = useParams();
  const { addToastMessage } = useAppToastContext();
  const [regenPdfMutation, { loading }] = usePdfRegen(Number(formId));
  const regenPdf = useCallback(() => {
    regenPdfMutation()
      .then(() => {
        addToastMessage({
          type: "success",
          header: "Success",
          body: "PDF Regenerated",
        });
      })
      .catch((e) => {
        console.error(e);
        addToastMessage({
          type: "error",
          header: "Error",
          body: "Error Regenerating PDF",
        });
      });
  }, [addToastMessage, regenPdfMutation]);

  const buttonContent = loading ? "Generating..." : "Regenerate PDF";
  return (
    <>
      <WithDebug>
        <Debugger />
      </WithDebug>
      <AuthGuard roles={["admin"]}>
        <StyledButton
          disabled={loading}
          variant="warning"
          onClick={regenPdf}
        >
          {buttonContent}
        </StyledButton>
      </AuthGuard>
    </>
  );
};

const useViewPdfGov = ({ uuid }: { uuid?: string }) => {
  const [getUrl, { loading, error }] = useJurisdictionFormPdf();

  const openPdf = useCallback(() => {
    getUrl().then(({ data }) => {
      const url = data?.jurisdiction?.form?.currentSubmission?.pdf?.url;
      console.log("Data", data);
      if (!url) {
        console.error("Unable to retrieve URL");
        return;
      }

      window.open(url, "_blank");
    });
  }, [getUrl]);

  return [openPdf, { loading, error }];
};

interface PDFFormGuardProps {
  account: Account;
  alt?: ReactNode;
}

const PDFFormGuard: FC<PropsWithChildren<PDFFormGuardProps>> = ({
  alt,
  account,
  children,
}) => {
  const { data, loading } = useJurisdictionForm();
  const isDigitalOnly = data?.jurisdiction?.form?.digitalOnly;
  if (loading) return null;

  if (isDigitalOnly) {
    return <>{alt || null}</>;
  }

  return <>{children}</>;
};

const DecisionContainer: FC<DecisionContainerProps> = ({
  account,
  refreshAccount,
  currentUser,
}) => {
  const { userList } = useUserListContext();
  const { addToastMessage } = useAppToastContext();
  const { addModal, removeModal } = useAppModalContext();

  // cache-only here, as this should already be triggered when this page is
  // rendered.
  const { data } = useJurisdictionForm({ fetchPolicy: "cache-only" });
  const uuid = data?.jurisdiction?.form?.ddbKey;
  const [viewPdf] = useViewPdfGov({ uuid });

  const failToast = useCallback(
    (errorMsg: string | null = null) =>
      addToastMessage({
        type: "danger",
        header: "Failed",
        body: errorMsg || "The attempt to update the form has failed",
      }),
    [addToastMessage],
  );

  const handleUpdateFailed = useCallback(
    (error: string | { statusCode: number; message: string }) => {
      if (typeof error === "string") {
        // default error
        const msg = `The attempt to update the form has failed. ${error}`;
        failToast(msg);
        return;
      }

      if ("statusCode" in error && "message" in error) {
        // Sync format
        const { statusCode, message } = error;
        const msg = SyncErrorHandler(statusCode, message);
        failToast(msg);
        return;
      }

      const defaultMsg = "The attempt to update the form has failed";
      failToast(defaultMsg);
    },
    [failToast],
  );

  const closeModal = useCallback(() => {
    removeModal();
  }, [removeModal]);

  const successUpdate = useCallback(() => {
    closeModal();
    addToastMessage({
      type: "success",
      header: "Updated",
      body: "The form has been updated",
    });
    refreshAccount();
  }, [addToastMessage, closeModal, refreshAccount]);

  const checkAssignedUser = useCallback(
    (userId: number) => {
      const userAssigned = userList.find(
        ({ loginId }) => Number(loginId) === userId,
      );
      if (!userId || (userAssigned && !userAssigned.active)) {
        return true;
      }
      return false;
    },
    [userList],
  );

  const handleUpdateFormDecision = useCallback(
    async (decision: string, msg: string = "") => {
      try {
        if (
          checkAssignedUser(account.assignedToId) &&
          account.status === "Delivered"
        ) {
          await updateFormAssignedUser(
            account.submissionId,
            Number(currentUser.loginId),
          );
        }
        const response = await setFormDecision(account.formId, decision, msg);
        if (response.error) {
          handleUpdateFailed(response.error);
        } else {
          if (response.ok === "denied") {
            handleUpdateFailed("The form has been denied.");
          } else {
            successUpdate();
          }
        }
      } catch (err) {
        failToast();
      }
    },
    [
      account,
      checkAssignedUser,
      currentUser.loginId,
      failToast,
      handleUpdateFailed,
      successUpdate,
    ],
  );

  const openModal = useCallback(
    (decision: string) => {
      addModal({
        size: "lg",
        header: "A message to the taxpayer is required.",
        body: (
          <Body
            updateFormDecision={handleUpdateFormDecision}
            decision={decision}
            closeModal={closeModal}
          />
        ),
      });
    },
    [addModal, closeModal, handleUpdateFormDecision],
  );

  return (
    <>
      <DecisionButtonsContainer
        account={account}
        refreshAccount={refreshAccount}
        handleUpdateFormDecision={handleUpdateFormDecision}
        openModal={openModal}
      />
      <PDFFormGuard account={account}>
        <StyledButton
          variant="light"
          onClick={viewPdf}
        >
          View PDF
        </StyledButton>
        <PdfRegenButton />
        <BSAExportButton />
      </PDFFormGuard>
      <NCIPPPrintButton />
    </>
  );
};

export default DecisionContainer;

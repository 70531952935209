import React, { FC } from "react";
import { Row, Col } from "react-bootstrap";
import { FormDataUnion, Submission } from "@taxscribe/gql-queries";
import { GAHomesteadExemption, GAHomesteadExemptionData, Metadata } from "@taxscribe/form";

interface GAHomesteadExemptionViewProps {
  formState: FormDataUnion;
  metadata: Partial<Submission> & Metadata;
}

const GAHomesteadExemptionView: FC<GAHomesteadExemptionViewProps> = ({
  metadata,
  formState,
}) => {
  const data = formState as GAHomesteadExemptionData;
  return (
    <GAHomesteadExemption.Provider
      readOnly
      metadata={metadata}
      initialState={data}
    >
      <Row>
        <Col sm={2}>
          <GAHomesteadExemption.Nav />
        </Col>
        <Col>
          <GAHomesteadExemption.Router />
        </Col>
      </Row>
    </GAHomesteadExemption.Provider>
  );
};

export default GAHomesteadExemptionView;

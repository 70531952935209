import React, { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FormDataUnion } from "@taxscribe/gql-queries";
import { Metadata, NCExtension, NCExtensionData } from "@taxscribe/form";

import { CurrentSubmission, FormPeriod } from "../queries/returnTypes";
import NCPTSDebugHelper from "../../../shared/NCPTSDebugHelper";
import ShowPDFOrForm from "../../../shared/ShowPDFOrForm";

interface NCExtensionViewProps {
  formState: FormDataUnion;
  formPeriod: FormPeriod;
  metadata: Partial<CurrentSubmission> & Metadata;
}

const NCExtensionView: FC<NCExtensionViewProps> = ({
  metadata,
  formState,
  formPeriod,
}) => {
  const data = formState as NCExtensionData;
  const filingDeadline = new Date(
    formPeriod.filingDeadlineFormattedDay,
  ).toLocaleDateString();
  return (
    <ShowPDFOrForm formPeriod={formPeriod}>
      <NCExtension.Provider
        readOnly
        metadata={metadata}
        initialState={data}
      >
        <Container fluid>
          <Row>
            <Col sm={12}>
              <NCExtension.ExtensionForm filingDeadline={filingDeadline} />
            </Col>
          </Row>
        </Container>
        <NCPTSDebugHelper />
      </NCExtension.Provider>
    </ShowPDFOrForm>
  );
};

export default NCExtensionView;

import { FC, useState, useEffect, useCallback } from "react";
import { formPeriod } from "../../../../utils/Interfaces";
import FormPeriodInput from "./FormPeriodInput";

interface PeriodsHandlerProps {
  formPeriods: Array<formPeriod>;
  setFormPeriods: Function;
}

const PeriodsHandler: FC<PeriodsHandlerProps> = ({
  formPeriods,
  setFormPeriods,
}) => {
  // For the moment we only want to show listings
  const shouldShow = ["Listing"];

  const getDefaultValue = (formType: string) => {
    return formPeriods.find(
      (formPeriod: formPeriod) => formPeriod.formType === formType,
    );
  };

  const exemptionDefault = getDefaultValue("Exemption");
  const extensionDefault = getDefaultValue("Extension");
  const listingDefault = getDefaultValue("Listing");

  const [exemption, setExemption] = useState(exemptionDefault);
  const [extension, setExtension] = useState(extensionDefault);
  const [listing, setListing] = useState(listingDefault);

  const getRespectiveState = useCallback((formPeriod: formPeriod) => {
    const { formType } = formPeriod;

    if (formType === "Exemption") {
      return {
        formPeriod: exemption,
        setter: setExemption,
      };
    }
    if (formType === "Extension") {
      return {
        formPeriod: extension,
        setter: setExtension,
      };
    }
    if (formType === "Listing") {
      return {
        formPeriod: listing,
        setter: setListing,
      };
    }

    return {
      formPeriod: undefined,
      setter: undefined,
    };
  }, [exemption, extension, listing]);

  const setNewData = useCallback(() => {
    // TODO: If these ever need to be updated (and I am sure they will) this
    // whole thing should probably be refactored here and server side.
    // const newData = [exemption, extension, listing];
    const newData = [listing];
    const result = newData.filter((form: formPeriod | undefined) => form);
    setFormPeriods(result);
  }, [listing, setFormPeriods]);

  useEffect(() => {
    setNewData();
  }, [setNewData]);

  return (
    <>
      {formPeriods.map((formPeriod: formPeriod) =>
        shouldShow.includes(formPeriod.formType) ? (
          <FormPeriodInput state={getRespectiveState(formPeriod)} />
        ) : null,
      )}
    </>
  );
};

export default PeriodsHandler;

import { createContext } from "react";
import { AssigneesObj } from "../utils/Interfaces";

interface IUserContextProps {
  user: AssigneesObj | null;
}

export const UserContext = createContext<IUserContextProps>({
  user: null,
});
